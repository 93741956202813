import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import { Resolver, SubmitHandler,Controller } from "react-hook-form";
import CustomSelect from "../../common/customSelect";
import {Spinner} from "@nextui-org/react";
import PhoneInput from 'react-phone-input-2';

export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
  handleSelectDoc,
  docholder,
  isLoading,
  isModalLoading,
  companyID,
  control
}) {
  const [data, setData] = useState(editValue);
  const [code, setCode] = useState('in')
  //useEffect(() => { setVal(editValue);  }, [editValue] )
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Modal  size="3xl"
    backdrop="blur"  isOpen={isOpen} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                 {editValue ? "Update Fleet" : " Add Fleet"}
              </ModalHeader>
              {isModalLoading?(<Spinner size="large" />):(
              <ModalBody>
              <div className="row">
                  <div className="col-md-6">
                     <div className="mb-3 mt-2">
                          <Select
                            isDisabled={companyID?.user?.role==='SUPERADMIN' && editValue}
                            label="Select Doc Holder" 
                            labelPlacement="outside"
                            defaultSelectedKeys={editValue?.doc_holder?.id ? [editValue.doc_holder?.id.toString()] : []}
                              placeholder="Select Doc Holder"
                              {...register("doc_holder_id", {
                              })}
                              onChange={(e) => handleSelectDoc(e)}
                              name="doc_holder_id"
                              >
                              {docholder.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                  {option.name}
                                </SelectItem>
                              ))}
                          </Select>
                        <div className="error-msg">
                            {errors.doc_holder_id?.message} {errName}
                        </div>
                     </div>

                     <div className="mb-3 mt-2">
                        <Input
                            {...register("contact_person")}
                            id="contact_person"
                            type="text"
                            label="Contact Person"
                            labelPlacement="outside"
                            placeholder="Enter Your Contact Person"
                            name="contact_person"
                          />
                      </div>
                      <div className="mb-3 mt-2">
                      <label style={{fontSize:'14px'}}>Telephone Number</label>
                      <Controller
                        name='telephone'
                        control={control}
                        className="form-control"
                        render={({ field: { onChange } }) => (
                          <PhoneInput
                              value={editValue?.telephone}
                              enableSearch={true}
                              country={code}
                              onChange={(value, country, e, formattedValue) => {
                                setCode(country.countryCode)
                                onChange(value)
                              }}
                          /> 
                        )}
                        rules={{ required: true }}
                      />
                        {/* <Input
                          {...register("telephone")}
                          id="telephone"
                          type="text"
                          label="Telephone"
                          labelPlacement="outside"
                          placeholder="Enter your Telephone"
                          name="telephone"
                        /> */}
                      </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3 mt-2">
                        <Input
                            {...register("name")}
                            id="name"
                            type="text"
                            label="Fleet Name"
                            labelPlacement="outside"
                            placeholder="Enter your Fleet Name"
                            name="name"
                          />
                          <div className="error-msg">
                            {errors.name?.message} {errName}
                          </div>
                      </div>

                      <div className="mb-3 mt-2">
                        <Input
                            {...register("contact_email")}
                            id="contact_email"
                            type="text"
                            label="Contact Email"
                            labelPlacement="outside"
                            placeholder="Enter Your Contact Email"
                            name="contact_email"
                          />
                           <div className="error-msg">
                            {errors.contact_email?.message} {errName}
                          </div>
                      </div>
                      <div className="mb-3 mt-2">
                        <CustomSelect
                          name="status"
                          label="Select Status"
                          register={register}
                          handleSelectChange={handleSelectChange}
                          defaultSelectedKeys={[editValue?.status || "ACTIVE"]}
                          options="statusOptions"
                        />
                    </div>
                  </div>
                </div>
              </ModalBody>
              )}
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
