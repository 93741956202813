import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useDisclosure } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../../store/userStore';
import LoaderScreen from "../../common/LoaderScreen";
import RouteConstants from "../../../Constants/RouteConstant";

const validationSchema = yup.object({
  name: yup
    .string("⚠ Enter Office Name")
    .required("⚠ Office Name is required")
    .min(2, "⚠ Office Name must be more than 2 characters "),
    country_id: yup
    .string("⚠ Select Country")
    .required("⚠ Country is required")
});

const OfficeList = () => {
  const [result, setResult] = useState([]);
  const [country, setCountry] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [countrySelect, setCountrySelect] = useState("");
  const companyID = userStore();
  const [loading, setLoading] = useState(true)


  const InitCall =async ()=> {
    mutateAsync({ search: "", status: "", page: 0, size: 10 });
}

  useEffect(() => {
    InitCall();
  }, []);



  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useOfficeListMutation((response) => {
    setResult(response.data);
    setLoading(false)
  });

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleSelectCountry = (event) => {
    const newValue = event.target.value;
    setValue("country_id", newValue);
    //setCountrySelect(newValue);

  };



  const countryListing = authQueries.useCountryListMutation(
    (response) => {
      setCountry(response.data?.items);
    }
  );

  const setInitialValue = (data) => {
    countryListing.mutateAsync({page:0, size:1000})
    setEditValue(data);
    let datas = {
      id: data.id,
      company_id: companyID?.companyData?.company,
      name:data.name,
      address:data.address,
      city:data.city,
      country_id:data.country_id,
      zipcode:data.zipcode,
      telephone:data.telephone,
      status: data.status,
    };
    console.log("initivalues==>",data)
    setSelectedValue(data.status)
    reset({ ...datas });
    onOpen(true);
  };

  const AddData = authQueries.useOfficeAddMutation((response) => {
    if (response?.message === "Success") {
      navigate(RouteConstants.OFFICE);
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const updateData = authQueries.useOfficeUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      mutateAsync({ search: "", status: "", page: 0, size: 10 });
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteData = authQueries.useOfficeMutationDelete((response) => {
    mutateAsync({ search: "", status: "ACTIVE", page: 0, size: 10 });
    messages.success("successfully deleted");
  });

  const onSubmit = (data) => {
    const datavalues = {
      company_id: companyID?.companyData?.company,
      name:data.name,
      address:data.address,
      city:data.city,
      country:parseInt(data.country_id),
      zipcode:data.zipcode,
      telephone:data.telephone,
      status: selectedValue,
    };
    console.log("datavalues",datavalues)
    if (!editValue) {
      AddData.mutateAsync(datavalues).then(() => {
        onOpenChange(false);
        mutateAsync({ search: "", status: "", page: 0, size: 10 });
        let datas = {
          name:"",
          address:"",
          city:"",
          country:"",
          zipcode:"",
          telephone:"",
        };
        setSelectedValue("ACTIVE")
        reset({ ...datas });
      });
    } else {
      const datavalues = {
        id: editValue.id,
        company_id: companyID?.companyData?.company,
        name:data.name,
        address:data.address,
        city:data.city,
        country:parseInt(data.country_id),
        zipcode:data.zipcode,
        telephone:data.telephone,
        status: selectedValue,
      };
      updateData.mutateAsync(datavalues).then(() => {
        let datas = {
          name:"",
          address:"",
          city:"",
          country:"",
          zipcode:"",
          telephone:"",
        };
        reset({ ...datas });
      });
    }
  };

  const deleteEvent = (event, id) => {
    event.preventDefault();
    deleteData.mutateAsync(id);
  };

  return (
    <div className="container">
      <div className="row">
        {loading ? 
        <LoaderScreen />:
        <div className="col-md-12">
          <div class="row">
                <div class="col-md-6">
                    <h3 class="dashboard-section-title">Office </h3>
                </div>
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                        
                    </div>
                </div>
            </div>
          <div className="text-end p-3">
            <Button
              color="primary"
              className="btn btn-add"
              onPress={() => {
                setEditValue(null);
                onOpen(true);
                countryListing.mutateAsync({page:0, size:1000})
              }}
            >
              <i className="icon-add"></i> Add
            </Button>
          </div>
          <div className="table-wrpr">
            <div className="table-section">
              <table className="table">
                <thead>
                  <tr>
                    <th width="20">#</th>
                    <th width="100">Office</th>
                    <th width="100">Company</th>
                    <th width="100">City</th>
                    <th width="100">Country</th>
                    <th width="100">Zipcode</th>
                    <th width="100">Phone</th>
                    <th width="250">Status</th>
                    <th width="50">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.items?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1) + result?.currentPage * 10}</td>
                      <td>{item?.name}</td>
                      <td>{item?.company?.name}</td>
                      <td>{item?.city}</td>
                      <td>{item?.country?.country}</td>
                      <td>{item?.zipcode}</td>
                      <td>{item?.telephone}</td>
                      <td>
                        <span className="status open">{item.status}</span>
                      </td>
                      <td className="action-links">
                      <a href="#"
                          onClick={() => setInitialValue(item)}
                          className="btn btn-view"
                        >
                           <i className="icon-edit"></i>
                        </a>

                        <Popconfirm
                          placement="left"
                          title="Are you sure to delete this ?"
                          onConfirm={(event) => {
                            deleteEvent(event, item.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className="btn btn-view"> <i className="icon-delete"></i></button>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination-wrpr">
            <Pagination  
            showControls
            total={result?.totalPages} 
            onChange={(e) => {mutateAsync({search: '', status: selectedValue, page: e-1 , size:pageSize},setCurrentPage(e-1))}}
            initialPage={1}  
            />
          </div>
        </div>
        }
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        register={register}
        errors={errors}
        errName={errName}
        handleSelectCountry={handleSelectCountry}
        country={country}
      />
    </div>
  );
};
export default OfficeList;
