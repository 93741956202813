import React, { useEffect, useState } from "react";
import authQueries from "../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { Button, useDisclosure } from "@nextui-org/react";
import "../../assets/css/tailwind.css";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../store/userStore';
import {Spinner} from "@nextui-org/react";
import { message, message as messages, Popconfirm } from "antd";
import { formatTimestamp } from '../../helpers/dateFormatter';

const VesselReport = () => {
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [isLoading, setIsLoading] = useState(false);
  const companyID = userStore();
  const [fromDate, setfromDate] = useState('2023-01-01');
  const [toDate, settoDate] = useState('2023-12-31');

  useEffect(() => {
    let filterValues = {
			fromDate,
			toDate,
		};
       companyID.setReportFilter(filterValues);
       setIsLoading(true); 
       mutateAsync({ search: "", status: "", page: 0, size: 10, schema: companyID?.companyData?.company, start:fromDate, end:toDate })
      .then(() => {
        setIsLoading(false); 
      })
      .catch(() => {
        setIsLoading(false); 
      });
  }, []);

  
  const InitCall =async ()=> {
    console.log("companyID?.reportFilter",companyID?.reportFilter)
    setIsLoading(true); 
    mutateAsync({ search: "", status: "", page: 0, size: 10, schema: companyID?.companyData?.company, start:companyID?.reportFilter?.fromDate, end:companyID?.reportFilter?.toDate })
    .then(() => {
      setIsLoading(false); 
    })
    .catch(() => {
      setIsLoading(false); 
    });

}

useEffect(() => {
  InitCall();
}, [companyID]);


  const { mutateAsync } = authQueries.useVesselReportList((response) => {
    setResult(response.data);
  });

  const Export = () => {
    vesselExport.mutateAsync({schema:companyID?.companyData?.company, start:companyID?.reportFilter?.fromDate, end:companyID?.reportFilter?.toDate})
}

const vesselExport = authQueries.useVesselStatusReportList(
    (response) => {
        if(response.filename){
            window.open(`${process.env.REACT_APP_BASE_URL}/api/report/download/${response.filename}`, '_blank');
        }
    }   
);

// const handlefromDate = (event) => {
//   const value = event.target.value;
//   console.log("fromdate",value)
//   setfromDate(value);
// };

// const handletoDate = (event) => {
//   const value = event.target.value;
//   console.log("todate",value)
//   settoDate(value);
// };


  

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">

        <div class="row">
                <div class="col-md-6 text-start p-3 pt-0 pe-2">
                    <h4 className="dashboard-section-title">Vessel Status Report</h4>
                </div>
                <div class="col-md-6 text-end p-3 pt-0 pe-2">
                <button
             
             className="btn btn-add btn-darkblue"
             onClick={Export}
           >
             <i className="icon-file-excel"></i> Export
           </button>
                </div>
        </div>
          {isLoading ? (
          <div className="loader" >
            <Spinner size="large" />
          </div>
           ) : (
          <div className="table-wrpr">
            <div className="table-section">
              <table className="table">
                <thead>
                  <tr>
                    <th width="100">Vessel Name</th>
                    <th width="100">IMO</th>
                    <th width="100">Account User</th>
                    <th width="100">Customer ID</th>
                    <th width="100">Doc Holder</th>
                    <th width="100">Fleet</th>
                    <th width="100">Creation Date</th>
                    <th width="100">Deactivation Date</th>
                    <th width="100">Active Days</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.map((item, index) => (
                    <tr key={index}>
                       <td>{item?.name}</td>
                       <td>{item?.imo}</td>
                       <td>{item?.user}</td>
                       <td>{item?.cust_id}</td>
                       <td>{item?.doc}</td>
                       <td>{item?.fleet}</td>
                       <td>{ item?.activeDate!=='ACTIVE'?formatTimestamp(item?.activeDate):'ACTIVE'}</td>
                       <td>{item?.deactiveDate!=='ACTIVE'?formatTimestamp(item?.deactiveDate):'ACTIVE'}</td>
                       <td><b>{item?.activeDays}</b></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          )}
          <div className="pagination-wrpr">
            <Pagination  
            showControls
            total={result?.totalPages} 
            onChange={(e) => {mutateAsync({search: '', status: selectedValue, page: e-1 , size:pageSize},setCurrentPage(e-1))}}
            initialPage={1}  
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default VesselReport;
