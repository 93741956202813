import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useDisclosure,  } from "@nextui-org/react";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import CustomDropDown from "../../common/dropDown";
import { userStore } from '../../../store/userStore';
import {Spinner} from "@nextui-org/react";
import { formatTimestamp } from '../../../helpers/dateFormatter';
import RouteConstants from "../../../Constants/RouteConstant";

const validationSchema = yup.object({
  comp_code: yup
    .string("⚠ Enter Company Code")
    .required("⚠ Company Code is required")
    .min(3, "⚠ Company Code must be Min  3 characters "),
    name: yup
    .string("⚠ Enter Company Name")
    .required("⚠ Company Name is required")
    .min(2, "⚠Company Name must be more than 2 characters "),
    file: yup
    .string("⚠ please select company logo")

});

export default function ShipAdmin() {
    const [result, setResult] = useState([]);
    const [resultData, setResultData] = useState([]);
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState("ACTIVE");
    const [booleanSelect, setbooleanSelect] = useState("NO");
    const [errName, setErrName] = useState("");
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [editValue, setEditValue] = useState(null);
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [image, setImage] = useState('');
    const [imageData, setimageData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isbuttonLoading, setIsbuttonLoading] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [comanyDataCount, setcompanyDataCount] = useState({});
    const [code, setCode] = useState('in')
    const companydata = userStore();


    useEffect(() => {
      setIsLoading(true); 
      mutateAsync({ search: "", status: "", page: 0, size: 10 })
      .then(() => {
          setIsLoading(false); 
      })
      .catch(() => {
        setIsLoading(false); 
      });
      //companyDataCount.mutateAsync({search: "", status: "", page:0, size:1000, schema:companydata?.companyData?.company});
    }, []);

    
      const {
        register,
        reset,
        control,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(validationSchema),
      });
    
      const { mutateAsync } = authQueries.useCompanycountListMutation((response) => {
        console.log('test data',response.data.dataItems)
        setResult(response?.data);
      });
    
      const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
      };
    
      const handleSelectBoolean = (event) => {
        const newValue = event.target.value;
        setbooleanSelect(newValue);
      };
    
      const setInitialValue = (data) => {
        setEditValue(data);
        let datas = {
          id: data.id,
          comp_code: data.comp_code,
          name:data.name,
          email:data.email,
          phone:data.phone,
          address:data.address,
          short_name:data.short_name,
          parent_company:booleanSelect,
          description:data.description,
          status: selectedValue,
        };
        setSelectedValue(data.status)
        setbooleanSelect(data.parent_company)
        reset({ ...datas });
        onOpen(true);
      };
    
      const AddData = authQueries.useCompanyAddMutation((response) => {
        if (response?.message === "Success") {
          onOpenChange(false);
          setimageData()
          mutateAsync({ search: "", status: "", page: 0, size: 10 });
        } else if (response?.error[0]?.name) {
          setErrName("⚠ " + response?.error[0]?.name);
        }
      });
    
      const updateData = authQueries.useCompanyUpdateMutation((response) => {
        if (response?.message === "Success") {
          onOpenChange(false);
          mutateAsync({ search: "", status: "", page: 0, size: 10 });
        } else if (response?.error[0].name) {
          setErrName("⚠ " + response?.error[0].name);
        }
      });
    
      const deleteData = authQueries.useCompanyMutationDelete((response) => {
        mutateAsync({ search: "", status: "ACTIVE", page: 0, size: 10 });
        messages.success("successfully deleted");
      });
    
      const handleFileChange =(e)=>{
        setimageData(e)
      }
    
    const imageUpload = authQueries.useImageUploadSingleMutation(
      (response) => {
        
          setImage(response?.file?.filename)
      }   
    );

    const companyDataCount = authQueries.useCompanyDataCountMutation(
      (response) => {
        setcompanyDataCount(response?.counts)
      }
    );
    
    const user = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.USER)
    }

    const vessel = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.VESSEL)
    }

    const doc = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.DOC)
    }

    const issues = async (company) =>{
      console.log("company",company)
      await companydata.setCompanyData({ company:company.id  });
      await companydata.setCompany({ company  });
      navigate(RouteConstants.ISSUES)
    }

    const fleet = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.FLEET)
    }

    const report = async (company) =>{
      await companydata.setCompanyData({ company:company.id  })
      await companydata.setCompany({ company  });
      navigate(RouteConstants.REPORT)
    }
    
      const onSubmit = async(data) => {
        setIsbuttonLoading(true);
        const datavalues = {
          comp_code: data.comp_code,
          name:data.name,
          email:data.email,
          phone:data.phone,
          address:data.address,
          short_name:data.short_name,
          parent_company:booleanSelect,
          description:data.description,
          status: selectedValue,
          url:''
        };
        if (!editValue) {

          if(imageData){
            const fileData = new FormData();
            fileData.append('file', imageData)
            await imageUpload.mutateAsync(fileData).then(res=> {
              datavalues.url = res?.file?.filename
            })
          }

          AddData.mutateAsync(datavalues).then(() => {
            setIsbuttonLoading(false);           
            let datas = {
              comp_code: "",
              name:"",
              email:"",
              phone:"",
              address:"",
              short_name:"",
              description:"",
              parent_company:"NO",
              status: "ACTIVE",
            };
            setSelectedValue("ACTIVE")
            setbooleanSelect("NO")
            reset({ ...datas });
          });
        } else {
          const datavalues = {
            id: editValue.id,
            comp_code: data.comp_code,
            name:data.name,
            email:data.email,
            phone:data.phone,
            address:data.address,
            short_name:data.short_name,
            parent_company:booleanSelect,
            description:data.description,
            status: selectedValue,
            url: data.url
          };

          if(imageData){
            const fileData = new FormData();
            fileData.append('file', imageData)
            await imageUpload.mutateAsync(fileData).then(res=> {
              datavalues.url = res?.file?.filename
            })
          }

          updateData.mutateAsync(datavalues).then(() => {
            setIsbuttonLoading(false);
            let datas = {
              comp_code: "",
              name:"",
              email:"",
              phone:"",
              address:"",
              short_name:"",
              description:"",
              parent_company:"NO",
            };
            reset({ ...datas });
          });
        }
      };


      const Export = () => {
        issueExport.mutateAsync()
    }
    
    const issueExport = authQueries.useCompanyReportMutation
    (
        (response) => {
            if(response.fileName){
                window.open(`${process.env.REACT_APP_BASE_URL}/api/report/complaintdownload/${response.fileName}`, '_blank');
            }
        }   
    );



  return (
    <>
    <div className="dashboard-cards" style={{ display: 'none' }}>
    
        <div className="dashboard-card card purple">
            <div className="card-body">
                <div className="card-content">
                    <div className="card-label">Vessels</div>
                    <div className="card-count">820</div>
                </div>
                <div className="card-icon">
                    <i className="custom-icon-ship"><img src={require("../../../assets/images/ship-icon.png")}  /></i>
                </div>
            </div>
        </div>
        <div className="dashboard-card card red">
            <div className="card-body">
                <div className="card-content">
                    <div className="card-label">Companies</div>
                    <div className="card-count">230</div>
                </div>
                <div className="card-icon">
                    <i className="icon-domain"></i>
                </div>
            </div>
        </div>
        <div className="dashboard-card card safron">
            <div className="card-body">
                <div className="card-content">
                    <div className="card-label">No Code Informants</div>
                    <div className="card-count">72</div>
                </div>
                <div className="card-icon">
                    <i className="icon-users1"></i>
                </div>
            </div>
        </div>
        <div className="dashboard-card card violet">
            <div className="card-body">
                <div className="card-content">
                    <div className="card-label">Doc Holder</div>
                    <div className="card-count">19</div>
                </div>
                <div className="card-icon">
                    <i className="icon-document"></i>
                </div>
            </div>
        </div>
    </div>
    <div className="companies-heading">
        <div className="row">
            <div className="col-md-6 d-flex align-items-center">
                <h4>Companies</h4>
            </div>
            <div className="col-md-6 text-end">
                <a href="#"  onClick={()=>{  
                  setEditValue(null);
                  onOpen(true);}} className="btn btn-add btn-addcompany"><i className="icon-domain"></i> Add new company</a>

                    <button  style={{marginLeft:"5px"}} 
                          className="btn btn-add btn-darkblue"
                          onClick={Export}
                        >
                          <i className="icon-file-excel"></i> Export
                    </button>
            </div>
        </div>
    </div>
    {isLoading ? 
      (
      <div className="loader" >
            <Spinner size="large" />
      </div>
      ) : (
    <div className="companies-list">
    {result?.items?.map((item, index) => {
      console.log("item",item)
       //companyDataCount.mutateAsync({search: "", status: "", page:0, size:1000, schema:item.id});
      return (
        
                    <div className="row list-items">
                        <div className="col-md-1 pe-0 w-5">
                            <div className="company-number">
                                #
                                <span className="number">{(index + 1) + result?.currentPage * 10}</span>
                            </div>
                            
                        </div>
                        <div className="col-md-2 col-md-2 ps-0">
                            <div className="company-name">
                                Company 
                                <span className="c-name">{item?.name}</span>
                              
                            </div>
                            
                        </div>
                        <div className="col-md-2 col-md-2 pe-0 ps-0">
                            <div className="contact-name-number">
                                address
                                <span className="contactname">{item?.address}</span>
                              
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="starteddate">
                                Started Date
                                <span className="date"> {formatTimestamp(item?.createdAt)}</span>
                            </div>
                        </div>
                        <div className="col-md-5 d-flex justify-content-end pe-0">
                            <div className="list-action-wrpr text-right roundicons-text">
                                <a className="vessel" onClick={()=>{vessel(item?.company)}} data-toggle="tooltip" title="" data-original-title="Vessels">
                                    <i data-count={item?.vessel_count} className="vessels icon-custom-image"></i>
                                    <span>Vessels</span>
                                </a>
                                <a className="docholder" onClick={()=>{doc(item?.company)}} data-toggle="tooltip" title="" data-original-title="Doc Holder">
                                    <i data-count={item?.doc_count} className="docholder icon-custom-image"></i>
                                    <span>Doc Holder</span>
                                </a>
                                <a className="fleet" onClick={()=>{fleet(item?.company)}} data-toggle="tooltip" title="" data-original-title="Vessels">
                                    <i data-count={item?.fleet_count} className="vessels icon-custom-image"></i>
                                    <span>Fleets</span>
                                </a>
                                <a className="users" onClick={()=>{user(item?.company)}} data-toggle="tooltip" title="" data-original-title="Users">
                                    <i data-count={item?.user_count} className="users icon-users1"></i>
                                    <span>Users</span>
                                </a>
                                
                                <a className="issues" onClick={()=>{issues(item?.company)}} data-toggle="tooltip" title="" data-original-title="Open Issues">
                                    <i data-count={item?.issues_count} className="open-issues icon-info-outline"></i>
                                    <span>Open Issues</span>
                                </a>
                            </div>
                        </div>
                    </div>
                          )
                    })} 
                    <div className="pagination-wrpr">
                    <Pagination  
                      showControls
                      total={result?.totalPages} 
                      onChange={(e) => {mutateAsync({search: '', status: selectedValue, page: e-1 , size:pageSize},setCurrentPage(e-1))}}
                      initialPage={1}  
                    />
                    </div>
                  </div>
    )}

    <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        register={register}
        errors={errors}
        errName={errName}
        handleSelectChange={handleSelectChange}
        handleSelectBoolean={handleSelectBoolean}
        handleFileChange={handleFileChange}
        isLoading={isbuttonLoading}
        isModalLoading={isModalLoading}
        control={control}
        code={code}
        setCode={setCode}
      />
</>
  )
}