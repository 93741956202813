import React, { useState, useEffect } from 'react';
import {Checkbox} from "@nextui-org/react";

function CheckboxList({data, selectedIds,categoryIdsDisabled, checkboxes, setCheckboxes}) {


  useEffect(() => {
    // Set the initial checked state for certain checkboxes
console.log("selectedIds===>",selectedIds)
    const checkboxesData = data.map((item, index) => ({
      id: parseInt(item.id),
      name: item.category,
      checked: selectedIds.includes(parseInt(item.id)),
      isDisabled:categoryIdsDisabled.includes(parseInt(item.id)),
      created_by:item.created_by,
      status:item.status
      
    }));

    
    setCheckboxes(checkboxesData);
  }, []);

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setCheckboxes(updatedCheckboxes);
    console.log("checkboxes",checkboxes)
  };

  return (
    <div>
      {checkboxes.map((checkbox, index) => (
            <div className='custom-check-wrpr'>
                <label key={index}>
                    <Checkbox size="lg" onChange={() => handleCheckboxChange(index)} isDisabled={checkbox.isDisabled} defaultSelected={checkbox.checked}>{checkbox.name}</Checkbox>
                </label>
            </div>
      ))}
    </div>
  );
}

export default CheckboxList;
