import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { userStore } from '../../store/userStore';
import authQueries from "../../queries/authQueries";
import RouteConstants from "../../Constants/RouteConstant";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
Input,
Textarea,
Select,
  SelectItem,
} from "@nextui-org/react";
import {Spinner} from "@nextui-org/react";
import { message as messages } from "antd";

const validationSchema = yup.object({
  username: yup
    .string("⚠ Enter your Username")
    .required("⚠ Username is required"),
  password: yup
    .string("⚠ Enter your password")
    .required("⚠ Password is required")
});

const Login = () => {
  const user = userStore();
  const navigate = useNavigate();
  const [isError, setIsError] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [errName, setErrName] = useState("");
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


const schema2 = yup.object().shape({

  email: yup.string().required('⚠ Email is required').email('⚠ Invalid email address'),

});

const {
  register: register2,
  handleSubmit: handleSubmit2,
  formState: { errors: errors2 },
} = useForm({
  resolver: yupResolver(schema2),
});
  

  useEffect(() => {

    if (user.user.role === "SUPERADMIN" || user.user.role === "SHIPCONREPADMIN" || user.user.role === "ACCOUNTUSER" || user.user.role === "SUPERUSER") {
      navigate("/dashboard");
    }else{
      navigate("/login");
    }
  }, []);


  const { mutateAsync } = authQueries.useLoginMutation(async (response) => {
    if (response?.message === "Username or password is incorrect.") {
      setIsError(response.message);
      return;
    }
  
    if (response?.data?.id) {
      user.setSideBarActiveTab("DASHBOARD");
      console.log("response?.data", response?.data);
      user.setUser({ ...response?.data });
  
      await sessionStorage.setItem("accessToken", response.data?.jwttoken);
  
      if (response?.data?.companyDetail) {
        await user.setCompany({ company:response.data.companyDetail });

        await user.setCompanyData({ company: response.data.companyDetail.id  })
      }
  
      window.location.reload();
      // You might want to use 'navigate' here if 'RouteConstants.DASHBOARD' is a valid route.
      navigate(RouteConstants.DASHBOARD);
    }
  });
  
  const onSubmit = (data) => {
    const datavalues = {
      username: data.username,
      password: data.password,
    };
    //console.log("datavalues",datavalues)
    mutateAsync(datavalues);
  };

  const onSubmitForm2 = (data) => {
    setErrName("");
    setIsLoading(true)
    const datavalues={
      email:data.email
    }
    forgotPass.mutateAsync(datavalues).then((res) => {
      
    });
  };


  const forgotPass = authQueries.useForgotpass((response) => {

    if (response?.message === "Success") {
      setIsLoading(false)
      onOpenChange(false);
      messages.success("Please check your email for password reset");
    } else if (response?.message ==="Email not found")
    {
      setIsLoading(false)
      setErrName("⚠ " + response?.message);
    }else if(response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

console.log("env",process.env)
  return (
    <>
    <div className="login">
      <div className="login-wrpr">
        <div className="row">
          <div className="col-md-12 logo-wrpr">
            <img
              className="logo"
              src={require("../../assets/images/logo.png")}
            />
          </div>
          <div className="col-md-6"></div>

          <div className="col-md-6">
            <div className="login-box">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 mt-3">
                  <label htmlFor="email" className="form-label">
                    Username:
                  </label>

                  <input
                    {...register("username")}
                    variant="outlined"
                    name="username"
                    id="email"
                    type="text"
                    className="form-control"
                  />

                  <div className="error-msg">{errors.username?.message}</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="pwd" className="form-label">
                    Password:
                  </label>
                  <div className="password-wrapper">
                  <input
                    variant="outlined"
                    control={control}
                    {...register("password")}
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    id="pwd"
                    name="password"
                  />
              
                  <i  onClick={togglePasswordVisibility}  className={`passwordshowhide ${showPassword ? 'icon-eye' : 'icon-eye-blocked '}`}  ></i>
                   
                    </div>
                  <div className="error-msg">{errors.password?.message}</div>
                </div>
                <div className="login-error-msg">{isError ? isError : ""}</div>
                <div className="signin-btn-wrpr text-center">
                  <button type="submit" className="btn btn-signin btn-primary">
                    Sign In{" "}
                    <img  alt=""
                      src={require("../../assets/images/sign-in-arrow-icon.png")}
                    />
                  </button>
                </div>
                <div className="backtohome-btn-wrpr text-center">
                  <a className="backtohome" href={process.env.REACT_APP_FRONT_BASE_URL}>
                    Back to home
                  </a>
                </div>

                <div className="backtohome-btn-wrpr text-center" style={{marginTop:'15px'}}>
                  <a className="backtohome" onClick={ () => { onOpen()}}  >
                    Forgot Password
                  </a>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal 
        backdrop="blur" 
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        size="lg"

      >
		 <form onSubmit={handleSubmit2(onSubmitForm2)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Forgot Password
              </ModalHeader>
			        <ModalBody>
                 <div className="col-md-10">
                    <div className="mb-3 mt-2">
                      <Input
                        {...register2("email")}
                        id="code"
                        type="text"
                        label="Enter your registered email"
                        labelPlacement="outside"
                        placeholder="Enter Email"
                      />
                      <div className="error-msg">
                      <div className="error-msg">
                        {errors2.email?.message} {errName}
                      </div>
                      </div>
                    </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                 SUBMIT
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
		</form>
      </Modal>
    </>
  );
};

export default Login;
