import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
	Input,
	Textarea,
	Select,
    SelectItem,
  } from "@nextui-org/react";
import '../assets/css/site.css';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FilterSelect from "../components/common/Layout/filterSelect";
import authQueries from "../queries/authQueries";
import {Spinner} from "@nextui-org/react";
import { message, message as messages, Popconfirm } from "antd";
import deviceSvg  from '../assets/images/site/muliple-device.svg';
import fileSvg  from '../assets/images/site/multiple-file.svg';
import feedbckSvg  from '../assets/images/site/informant-feedback.svg';
import alertSvg  from '../assets/images/site/alert.svg';
import { useNavigate, Link } from "react-router-dom";
import RouteConstants from "../Constants/RouteConstant";
import Contact from "./contactForm";


  const contactUsValidationSchema = yup.object({
	fname: yup
	  .string("⚠ Enter Your First Name")
	  .required("⚠ Your First Name is required")
	  .min(3, "⚠ Your First Name must be Min 3 characters "),
	lname: yup
	  .string("⚠ Enter Your Last Name")
	  .required("⚠ Your Last Name is required")
	  .min(3, "⚠ Your Last Name must be Min 3 characters "),
	email: yup
	  .string("⚠ Enter Email")
	  .required("⚠ Email is required")
	  .email("⚠ Enter a valid email address"),
	message: yup
	  .string("⚠ Enter Your Message")
	  .required("⚠ Message is required"),
  });




  

const Site = () => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
	const [errName, setErrName] = useState("");
	const [trailor, setTrailor] = useState(false);
	const [selectedValue, setSelectedValue] = useState("NO");
	const [isLoading, setIsLoading] = useState(false);
	const [previousScroll, setPreviousScroll] = useState(0);
	const navigate = useNavigate();
	const [isModalopen, setisModalopen]=useState(false);
	const FRONT_BASE_URL = process.env.REACT_FRONT_BASE_URL;


	const validationSchema = yup.object({
		comp_name: yup
		  .string("⚠ Enter Company Name")
		  .required("⚠ Company Name is required")
		  .min(3, "⚠ Company Name must be Min  3 characters "),
		email: yup
		  .string("⚠ Enter Email")
		  .required("⚠ Email is required")
		  .email("⚠ Enter a valid email address"),
		name: yup
		  .string("⚠ Enter Your Full Name")
		  .required("⚠ Your Full Name is required")
		  .min(3, "⚠ Your Full Name must be Min  3 characters "),
		telephone: yup
		  .string("⚠ Enter Your Telephone")
		  .required("⚠ Your Telephone is required"),
		  imo: yup.string().when('trailor', {
			is: (value) => trailor === true,
			then: (schema) => schema.required('⚠ IMO  is Required')
			.min(7, "⚠ IMO must be Min  7 Number")
			.test('is-integer', '⚠ IMO number Must be Integer', (value) => {
				if (!value) return true; // Allow empty values (handled by "required" rule)
				return Number.isInteger(Number(value));
			  }), 
			otherwise: (schema) => schema,
		  }),
		  
	  
	  });

	  


	const handleScroll = () => {

        const currentScroll = window.scrollY;

        if (currentScroll > 480) {
            NavTrans();
        } else {
            removeNavTrans();
        }

        if (currentScroll < 80) {
            showTopNav();
        } else if (currentScroll > 0 && currentScroll < document.body.scrollHeight - window.innerHeight) {
            if (currentScroll > previousScroll) {
                hideNav();
            } else {
                showNav();
            }
            setPreviousScroll(currentScroll);
        }
    };


	const hideNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-visible');
            navbar.classList.add('is-hidden');
        }
    };

    const showNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden');
            navbar.classList.add('is-visible', 'scrolling');
        }
    };

    const showTopNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden', 'scrolling');
            navbar.classList.add('is-visible');
        }
    };

    const NavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.add('whiteNav');
        }
    };

    const removeNavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('whiteNav');
        }
    };


	useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScroll]);

    useEffect(() => {
        AOS.init();
      }, []);

      const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(validationSchema),
      });

	  const {
		register: registerUser,
		reset: resetUser,
		handleSubmit: handleSubmitUser,
		formState: { errors: errorsUser },
	  } = useForm({
		resolver: yupResolver(contactUsValidationSchema),
	  });

	  const handleSelectChange = (event) => {
		const newValue = event.target.value;

		if(newValue==='YES'){
			setTrailor(true);
			setSelectedValue('YES')
		}else if(newValue==='NO'){
			setTrailor(false);
			setSelectedValue('NO')
		}

	  };

	  const Options = [
        { value: 'YES', label: 'YES' },
        { value: 'NO', label: 'NO' },
	];
	const onSubmit = async (data) => {
		setIsLoading(true);
		const datavalues = {
			company:data.comp_name,
		    name: data.name,
			email:data.email,
			telephone: data.telephone,
			mobile:data.phone,
			trailor: selectedValue ,
		    doccompny: data.doccompany,
		    imo: data.imo,
			message: data.message
		  };

		RequestAPI.mutateAsync(datavalues).then(() => {
			setTrailor(false);
			setIsLoading(false);
			let datas = {
				comp_name: "",
				name:"",
				email:"",
				telephone:"",
				phone:"",
				trailor:"NO",
				doccompany:"",
				imo:"",
				message: "",
			};
			reset({ ...datas });
		  });

	}

	const onContactSubmit = async (data) => {
		setIsLoading(true);
		const datavalues = {
		    fname: data.fname,
			lname: data.lname,
			email:data.email,
			telephone: data.telephone,
			mobile:data.phone,
			message: data.message
		  };

		  ContactAPI.mutateAsync(datavalues).then(() => {
			setTrailor(false);
			setIsLoading(false);
			let datas = {
				fname: "",
				lname: "",
				email:"",
				telephone:"",
				phone:"",
				message: "",
			};
			reset({ ...datas });
		  });

	}

	const RequestAPI = authQueries.useRequestDemo((response) => {
		if (response?.message === "Success") {
		messages.success("Request for Demo Submitted Successfully");
		  onOpenChange(false);
		} else if (response?.error[0].name) {
		  setErrName("⚠ " + response?.error[0].name);
		}
	  });

	  const ContactAPI = authQueries.useContactus((response) => {
		if (response?.message === "Success") {
		messages.success("Your Query has been Submitted Successfully");
		  setisModalopen(false);
		} else if (response?.error[0].name) {
		  setErrName("⚠ " + response?.error[0].name);
		}
	  });

return ( 
<>
<nav id="navbar" class="navbar navbar-expand-md  navbar-light  is-visible  fixed-top ">
    <div class="navbar-brand   order-1 order-md-0 dual-collapse2">
		<a onClick={()=>{navigate(RouteConstants.ROOT)}} class="navbar-brand mr-auto" >
			
			<img class="logo" 
             src={require("../assets/images/site/logo.png")}
             />
		</a>
		
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar">
            <span class="navbar-toggler-icon">
			  <span></span>
			</span>
        </button>
       
    </div>
    <div class="navbar-collapse collapse  mx-auto order-0 header-center-nav" id="collapsingNavbar">
        <ul class="navbar-nav me-auto">
        </ul>
		<ul class="navbar-nav ms-auto">
			
			<li class="nav-item">
                <a  class="nav-link employer-login" href={`/login`}>Employer Login</a>
            </li>
			
        </ul>
    </div>
    
	
</nav>


<header class="masthead ">
<div class="overlay"></div>
  <div class="container h-100">
    <div class="row h-100 text-center">
      <div class="col-12 bnr-top-pding ">
			<h1 data-aos="fade-down" >Shipping Industry Confidential Reporting</h1>
			<p data-aos="fade-down"  data-aos-delay="300">Helping you create a safer and meaningful workplace for your employees</p>
			<a data-aos="fade-down"  data-aos-delay="600" href={`/frontend`}  class="btn btn-primary btn-workplace-issu ">Submit Workplace Issue</a>
			
			
			<div class="banner-footer">
				<div class="ship-stearing" data-aos="fade-down"   data-aos-delay="600"><img  src={require("../assets/images/site/ship-stearing.png")}  /></div>
				<p data-aos="fade-down"  data-aos-delay="300" style={{fontWeight:"bold"}}>Discover our exclusive platform, ensuring utmost security and unwavering confidentiality, designed exclusively
for seafarers and shore-based staff within the Shipping industry. Empower your workforce to
confidentially address workplace concerns with ease.</p>
			</div>
			
			
      </div>
	  
    </div>
  </div>
  
</header>


<section className="section section-one">
	<div class="container">
		<div class="row informant-and-employers">
			<div class="col-md-4 desktoponly">
				<img data-aos="fade-down"  data-aos-delay="0" src={require("../assets/images/site/informant-pic.png")}  />
			</div>
			<div class="col-md-8">
				<h2 data-aos="fade-down"  data-aos-delay="0" data-title="Reporter">Reporter</h2>
				<img class="phoneonly" data-aos="fade-down"  data-aos-delay="0" src={require("../assets/images/site/informant-pic.png")}  />
				<div data-aos="fade-down"  data-aos-delay="300" class="informant-cntnt">
				<p>Our platform enables employees to swiftly notify employers about any
work-related matters, fostering a workplace of safety, adherence to
regulations, inclusivity, respect, and equal opportunities. Rest assured,
SHIPCONREP maintains the strictest confidentiality, safeguarding the
anonymity of all employees.</p>
				</div>
			</div>
			
		</div>
	</div>
</section>


<section class="section-two">
	<div class="container">
		<div class="row informant-and-employers">
			
			<div class="col-md-8">
				<h2 data-aos="fade-down"  data-aos-delay="0" data-title="Employers">Employers</h2>
				<img class="phoneonly"  data-aos="fade-down"  data-aos-delay="0" src={require("../assets/images/site/employers-pic.png")} />
				<div data-aos="fade-down"  data-aos-delay="300" class="informant-cntnt">
				<p>Through our platform, employers gain insight into individual concerns,
prevalent behaviors, and emerging trends, enabling them to take
effective measures. Employers can engage with and address employee
concerns promptly. SHIPCONREP facilitates attentive listening,
demonstrating employers' commitment to resolving issues.</p>
				</div>
			</div>
			<div class="col-md-4 desktoponly">
				<img  data-aos="fade-down"  data-aos-delay="0"  src={require("../assets/images/site/employers-pic.png")} />
			</div>
			
		</div>
	</div>
</section>




<div class="container-fluid blue-strip" data-aos="fade-down"  data-aos-delay="0">
	<div class="container">
		<div class="row">
			<div class="col-md-12 text-center">
				<h3>Consider SHIPCONREP your vital link, connecting employees and employers in addressing workplace challenges.
We facilitate open communication and resolution for a harmonious work environment.</h3>
			</div>
		</div>
	</div>
</div>



<section class="section-three">
	<div class="container">
		<div class="row">
			<div class="col-md-5">
				<img data-aos="fade-down"  data-aos-delay="0"  src={require("../assets/images/site/man-with-typewriter-machine.jpg")}  />
			</div>
			<div class="col-md-7">
				<div class="row small-cards" >
					<div class="col-md-6">
						<div class="card text-center" data-aos="fade-down"  data-aos-delay="300">
							<div class="card-body">
							    <img src={deviceSvg} alt="Multiple Device SVG" />
								<h4>Multiple Device <br/>Support</h4>
							</div>
						</div>
					</div>
					<div class="col-md-6 custom-pos-5">
						<div class="card  text-center" data-aos="fade-down"  data-aos-delay="600">
							<div class="card-body">
								<img src={fileSvg}  />
								<h4>Multiple file format supported to attach</h4>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="card  text-center" data-aos="fade-down"  data-aos-delay="900">
							<div class="card-body">
								<img  src={feedbckSvg} />
								<h4>Reporter receives feedback</h4>
							</div>
						</div>
					</div>
					<div class="col-md-6  custom-pos-5">
						<div class="card  text-center" data-aos="fade-down"  data-aos-delay="1100">
							<div class="card-body">
								<img  src={alertSvg}  />
								<h4>Employer Portal, alert & track</h4>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</section>




<div class="container-fluid join-with-us">
	<div class="container">
		<div class="row">
			<div class="col-md-5">	
				<img class="phoneonly" data-aos="fade-in"  data-aos-delay="900" src={require("../assets/images/site/two-confident-business-man-shaking-hands.jpg")}  />
				<h2 data-aos="fade-down"  data-aos-delay="0">Join hands with us</h2>
				<p data-aos="fade-down"  data-aos-delay="300">and make your workplace better.</p>
				<a data-aos="fade-down"  data-aos-delay="600" onClick={ () => { onOpen()}}  class="btn btn-default btn-request-demo">Request a Demo</a>
			</div>
			<div class="col-md-7">	
				<img class="desktoponly" data-aos="fade-in"  data-aos-delay="900" src={require("../assets/images/site/two-confident-business-man-shaking-hands.jpg")} />
			</div>
		</div>
	</div>
</div>

<div class="container download-app">
	<div class="row">
		<div class="col-md-6 pe-0 ps-0">
			<img data-aos="fade-in" alt=""  data-aos-delay="100" src={require("../assets/images/site/ship-pic.jpg")}  />
		</div>
		<div class="col-md-6 qr-code-wrpr text-center my-auto">
			<h3 class="mb-1 qr-text" data-aos="fade-up"  data-aos-delay="200">Scan the codes below & download our mobile app today!</h3>
				<p class="mb-5" data-aos="fade-up"  data-aos-delay="200">( you can also click and download)</p>
			<div class="row">
				<div class="col-md-1"></div>
				<div class="col-md-5 text-center qrwrapper" data-aos="fade-up"  data-aos-delay="300">
					<a href="https://apps.apple.com/in/app/shipconrep/id6477852971"><img alt="apps store" src={require("../assets/images/site/app-store.png")} /></a>
					<img  class="qr-code" alt="" src={require("../assets/images/site/iOS-Apple-SCR.png")}   />
				</div>
				<div class="col-md-5 text-center qrwrapper" data-aos="fade-up"  data-aos-delay="400">
					<a href="https://play.google.com/store/apps/details?id=com.shipconrepapp"><img  alt="" src={require("../assets/images/site/gplay.png")}  /></a>
					<img class="qr-code"  alt="" src={require("../assets/images/site/Android-SCR.png")}   />
				</div>
				<div class="col-md-1"></div>
			</div>
		</div>
	</div>
</div>


<footer className="footer">
	<div class="container">
		<div class="row">
			<div class="col-md-2">
				{/* <img src={require("../assets/images/site/Ship-icon-rep-logo.png")} /> */}
			</div>
			<div class="col-md-10 bottom-border">
				<div class="row">
					<div class="col-md-5">
						<h5>Shipconrep</h5>
						<div class="anchors">
							<a  href={RouteConstants.ABOUT}>About Us</a>
							<a  href={RouteConstants.FAQ}>FAQs</a>
							<a  href={RouteConstants.PRIVACYPOLICY} >Privacy Policy</a>
							<a className="contactUs" onClick={ ()=>{setisModalopen(true)}}>Contact Us</a>
						</div>
					</div>
					<div class="col-md-4">
						<h5>Services</h5>
						<div class="anchors">
							<a href="/frontend">Web Hotline</a>
							<a href="/login">Employer Portal</a>
						</div>
					</div>
					<div class="col-md-3">
						<h5>Email ID</h5>
						<div class="anchors">
							<a href="#">info@shipconrep.com</a>
						</div>
					</div>
					

					
					<div class="col-md-12">
						<hr class="border-white" />
					</div>

					<div class="col-md-12 text-center pt-3 pb-5">
						<h4>This portal is administered by:</h4>
						<p>Shipping Industry Confidential Reporting Limited. Registered in England and Wales: 13395969</p>
					</div>
					
					<div class="col-md-12">
					<div class="anchors" style={{display:'flex'}}>
					  <a className="social"  href="https://www.facebook.com/people/Shipping-Industry-Confidential-Reporting/100094405543592/"><icon class="icon-facebook2"></icon></a>
					  <a className="social"  href="https://www.linkedin.com/company/shipping-industry-confidential-reporting-pvt-ltd"><icon class="icon-linkedin"></icon></a>
					  <a className="social" href="https://www.instagram.com/shipconrep?igsh=dGo4cHFwMW9sbjM2"><icon class="icon-instagram1"></icon></a>
					  
					  </div>
					</div>
				</div>
				
			</div>
			
		</div>
	</div>
</footer>

	<Modal 
        backdrop="blur" 
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        size="2xl"

      >
		 <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
				Request a Demo
				<p className="demonote">Add your company information</p>
			  </ModalHeader>
			  <ModalBody>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3 mt-2">
                      <Input
                        {...register("comp_name")}
                        id="code"
                        type="text"
                        label={<div> Company Name <span className="required"></span></div>}
                        labelPlacement="outside"
                        placeholder="Enter Company Name"
						maxLength={200}
                      />
                      <div className="error-msg">
                        {errors.comp_name?.message} {errName}
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <Input
                        {...register("email")}
                        id="code"
                        type="text"
						label={<div> Work Email <span className="required"></span></div>}
                        labelPlacement="outside"
                        placeholder="Enter Work Email"
						maxLength={200}
                      />
                      <div className="error-msg">
                        {errors.email?.message} {errName}
                      </div>
                    </div>
                    <div className="mb-3 mt-2">
                      <Input
                        {...register("phone")}
                        id="name"
                        type="text"
                        label="Mobile Number"
                        labelPlacement="outside"
                        placeholder="Enter Mobile"
						maxLength={200}
                      />
                    </div>

					{trailor?(
					<div className="mb-3 mt-2">
                      <Input
                        {...register("doccompany")}
                        id="doccompany"
                        type="text"
                        label="Doc Company"
                        labelPlacement="outside"
                        placeholder="Doc company within your group"
						maxLength={200}
                      />
                      <div className="error-msg">
                        {errors.phone?.message} {errName}
                      </div>
                    </div>
					):null}
                  </div>


                  <div className="col-md-6">

                    <div className="mb-3 mt-2">
                      <Input
                        {...register("name")}
                        id="name"
                        type="text"
						label={<div> Your Full Name <span className="required"></span></div>}
                        labelPlacement="outside"
                        placeholder="Enter Full Name"
                        name="name"
						maxLength={200}
                      />
                      <div className="error-msg">
                        {errors.name?.message} {errName}
                      </div>
                    </div>
                    <div className="mb-3 mt-2">
						<Input
							{...register("telephone")}
							id="code"
							type="text"
							label={<div> Telephone Number<span className="required"></span></div>}
							labelPlacement="outside"
							placeholder="Enter Telephone"
							maxLength={200}
						/>
					  <div className="error-msg">
                        {errors.telephone?.message} {errName}
                      </div>
                    </div>

					<div className="mb-3 mt-2">
					<Select
                            label="Do you require a tailored demo?" 
                            labelPlacement="outside"
                            defaultSelectedKeys={['NO']}
                              placeholder="Do you require a tailored demo"
                              {...register("tailor", {
                              })}
                              onChange={(e) => handleSelectChange(e)}
                              name="tailor"
                              >
                              {Options.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  {option.label}
                                </SelectItem>
                              ))}
                          </Select>
                    </div>
					{trailor && (
					<div className="mb-3 mt-2">
                      <Input
                        {...register("imo")}
                        id="imo"
                        type="text"
                        label="DOC Company's IMO"
                        labelPlacement="outside"
                        placeholder="DOC Company's IMO"
						maxLength={7}
                      />
                      <div className="error-msg">
                        {errors.imo?.message} {errName}
                      </div>
                    </div>
					)}

                  </div>
				  <div className="mb-3 mt-2">
                      <Textarea
                        {...register("message")}
                        variant="faded"
                        label="Additional Message"
                        labelPlacement="outside"
                        placeholder="Enter Message"
                        name="message"
						maxLength={200}
                      />
                    </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                 SUBMIT
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
		</form>
      </Modal>

{/* contact Us */}

<Contact isModalopen={isModalopen} setisModalopen={setisModalopen} />

</>

)
}

export default Site

