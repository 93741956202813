
import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { userStore } from '../../../store/userStore';
import { useNavigate } from "react-router-dom";
import { Pagination } from '@nextui-org/react';
import {Spinner} from "@nextui-org/react";
import { formatTimestamp } from '../../../helpers/dateFormatter';
import RouteConstants from "../../../Constants/RouteConstant";
import {
	Modal,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	useDisclosure,
  Button
  } from "@nextui-org/react";

const CompanyDash = () => {

    const [result, setResult] = useState([]);
    const companyID = userStore();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [docholder, setHocholder] = useState([]);
    const [doc, setDoc]= useState('')
    const [isLoading, setIsLoading] = useState(false);
    const store = userStore();
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [whathappend, setWhathappened]=useState('')

    const InitCall =async ()=> {
      setIsLoading(true); 
        await mutateAsync({ 
        search: "", 
        status: "", 
        page: 0, 
        size: 10, 
        schema:companyID?.companyData?.company, 
        doc:store?.companyFilter?.selectedDoc, 
        vessel:store?.companyFilter?.selectedVessel,
        accountStatus:store?.companyFilter?.selectedAccntStatus,
        category:store?.companyFilter?.selectedCategory,
        platform:store?.companyFilter?.selectedPlatform,
        reference:store?.companyFilter?.selectedReference
    });
      await docListing.mutateAsync({search: "", status: "", page:0, size:1000, schema:companyID?.companyData?.company}).then((result)=>{});
        setIsLoading(false);  
      }
      const docListing = authQueries.useDocListMutation(
        (response) => {
          setHocholder(response?.data?.items);
        }
      );
    const { mutateAsync } = authQueries.useCompliantListMutation((response) => {
        setResult(response?.data);
      });
      
      useEffect(() => {
        
        let FilterValues={
			      selectedDoc:'',
            selectedVessel:'',
            selectedAccntStatus:'',
            selectedCategory:'',
            selectedPlatform:'',
            selectedReference:''
            
		}
        store.setSelectedFilter(FilterValues);
        mutateAsync({ 
            search: "", 
            status: "", 
            page: 0, 
            size: 10, 
            schema:companyID?.companyData?.company, 
            doc:'', 
            vessel:'' ,
            accountStatus:'',
            category:'',
            platform:'',
            reference:''
            
        });
      }, []);
     

      useEffect(() => {
        InitCall();
        setDoc(store?.companyFilter?.selectedDoc)
      }, [store]);


      const Export = () => {
        issueExport.mutateAsync(
          {
            search: "", 
            schema:companyID?.companyData?.company, 
            doc:store?.companyFilter?.selectedDoc, 
            vessel:store?.companyFilter?.selectedVessel,
            accountStatus:store?.companyFilter?.selectedAccntStatus,
            category:store?.companyFilter?.selectedCategory,
            platform:store?.companyFilter?.selectedPlatform,
            reference:store?.companyFilter?.selectedReference

          })
    }

    
    const ShipadminExport = () => {
      ashipadminissueExport.mutateAsync(
        {
          search: "", 
          schema:companyID?.companyData?.company, 
          doc:store?.companyFilter?.selectedDoc, 
          vessel:store?.companyFilter?.selectedVessel,
          accountStatus:store?.companyFilter?.selectedAccntStatus,
          category:store?.companyFilter?.selectedCategory,
          platform:store?.companyFilter?.selectedPlatform,
          reference:store?.companyFilter?.selectedReference

        })
  }
    
    const issueExport = authQueries.useComplaintReportMutation
    (
        (response) => {
            if(response.fileName){
                window.open(`${process.env.REACT_APP_BASE_URL}/api/report/complaintdownload/${response.fileName}`, '_blank');
            }
        }   
    );

    const ashipadminissueExport = authQueries.useShipAdminComplaintReportMutation
    (
        (response) => {
            if(response.fileName){
                window.open(`${process.env.REACT_APP_BASE_URL}/api/report/complaintdownload/${response.fileName}`, '_blank');
            }
        }   
    );
    

  return (
    <>
    <div class="container">
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
    <div class="container-fluid">
    <div class="row">
    {isLoading ? 
      (
      <div className="loader" >
            <Spinner size="large" />
      </div>
      ) : (
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="dashboard-section-title">Issues <span class="data-count">{result?.totalItems}</span></h3>
                </div>
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                    {store.user.role === "SUPERADMIN" ?
                      <button   
                          className="btn btn-add btn-darkblue"
                          onClick={Export}
                        >
                          <i className="icon-file-excel"></i> Export
                    </button>:null
                    }
                    {store.user.role === "SHIPCONREPADMIN" ?
                      <button   
                          className="btn btn-add btn-darkblue"
                          onClick={ShipadminExport}
                        >
                          <i className="icon-file-excel"></i> Export
                    </button>:null
                    }
                    </div>
                </div>
            </div>
            
            
            <div class="table-wrpr">
                <div class="table-section">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="20">#</th>
                                <th width="100">Issue Name</th>
                                <th width="100">Reference Number</th>
                                <th width="120">Reported Date</th>
                                {store?.user?.role==='SHIPCONREPADMIN'?<th width="80">Vessel</th>:null}
                                <th width="150">Doc Holder</th>
                                <th width="">Issue Type</th>
                                <th width="250">What Happened</th>

                                {store?.user?.role==='SHIPCONREPADMIN'?<th width="100">Platform</th>:null}
                                <th width="100">Status</th>
                                <th width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {result?.items?.map((item, index) => {
                        const categories = item?.categories?.filter(item => item.complaint_category && item.complaint_category.status === 'ACTIVE')
                        .map(item => item.category);
                        const categoryString = categories?.join(', ');
                        const issueName=item?.complaint_assigns?.filter(assign=> assign.isaccept==='YES');
                          return(
                          <tr>
                            <td> {(result?.totalItems - index) - result?.currentPage * 10}</td>
                            <td>{issueName?.[0]?.nameofissue}</td>
                            <td>{item?.reference_num}</td>
                            <td>{ formatTimestamp(item?.created_at)}</td>										
                            {store?.user?.role==='SHIPCONREPADMIN'?<td>{item.vessel?.name}</td>:null}
                            <td>{item.doc_holder?.name}</td>
                            <td>{categoryString}</td>
                            <td data-bs-toggle="tooltip" onClick={()=>{ setWhathappened(item?.what_happened); onOpen()}} title={item?.what_happened}>{  item?.what_happened.slice(0, 50) }<i class="icon-popout"></i></td>

                            {store?.user?.role==='SHIPCONREPADMIN'? <td>{item?.platform==='ANDROID' || item?.platform === 'IOS' || item?.platform === 'APP'?'MOB':'WEB'}</td>:null}

                             {item?.complaint_status?.status==='OPEN'?
                              <td><span class="status open">Open</span></td>:null
                             }
                            {item?.complaint_status?.status==='ACCEPTED'?
                              <td><span class="status accepted">ACCEPTED</span></td>:null
                             }
                            {item?.complaint_status?.status==='CLOSED'?
                              <td><span class="status closed">CLOSED</span></td>:null
                             }

                            <td class="action-links">
                                <a onClick={()=>{ navigate(RouteConstants.COMPLIANTVIEWURL+item.id);}} class="btn btn-view btn-round icon-eye"></a>
                            </td>
                          </tr>
                          
)})}
                          
                        </tbody>
                    </table>
                </div>  
            </div>
            
            <div class="pagination-wrpr">
                <Pagination  
                showControls
                total={result?.totalPages} 
                onChange={(e) => {mutateAsync({
                  search: '', 
                  page: e-1 , 
                  size:pageSize,
                  schema:companyID?.companyData?.company, 
                  doc:store?.companyFilter?.selectedDoc, 
                  vessel:store?.companyFilter?.selectedVessel,
                  accountStatus:store?.companyFilter?.selectedAccntStatus,
                  category:store?.companyFilter?.selectedCategory,
                  platform:store?.companyFilter?.selectedPlatform,
                  reference:store?.companyFilter?.selectedReference
                },
                  setCurrentPage(e-1))
                }}
                initialPage={1}  
                />
            </div>
            
        </div>
         )}
    </div>
</div>

{/* What  Happened */}
<Modal size='2xl'  isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">What Happened</ModalHeader>
              <ModalBody>
                {whathappend}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>


</>

  )
}

export default CompanyDash