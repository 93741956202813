import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import { Resolver, SubmitHandler, useForm, Controller } from "react-hook-form";
import CustomSelect from "../../common/customSelect";
import {Spinner} from "@nextui-org/react";
import PhoneInput from 'react-phone-input-2'

export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
  isLoading,
  companyID,
  control
}) {
  const [data, setData] = useState(editValue);
  const [code, setCode] = useState('in')
  //useEffect(() => { setVal(editValue);  }, [editValue] )
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Modal size="3xl" backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
              {editValue ? "Update Doc Holder" : " Add Doc Holder"}

              {companyID?.user?.role==='SUPERADMIN'?<p className="demonote">If you need to create a new DOC holder, please contact <a href="mailto:support@shipconrep.com">support@shipconrep.com</a></p>:null}
              </ModalHeader>
              <ModalBody>
                <div className="row">
                  <div className="col-md-6">
                    <div class="mb-3 mt-2">
                      <Input
                      isDisabled={companyID?.user?.role==='SUPERADMIN'}
                        {...register("name")}
                        id="name"
                        type="text"
                        label="Doc Holder Name"
                        labelPlacement="outside"
                        placeholder="Enter your Doc Holder Name"
                      />
                      <div className="error-msg">
                        {errors.name?.message} {errName}
                      </div>
                    </div>
                    <div class="mb-3 mt-2">
                      <Input
                        isDisabled={companyID?.user?.role==='SUPERADMIN'}
                        {...register("doc_code")}
                        id="code"
                        type="text"
                        label="Doc Code"
                        labelPlacement="outside"
                        placeholder="Enter your Doc Code"
                        maxLength="4"
                      />
                      <div className="error-msg">
                        {errors.doc_code?.message} {errName}
                      </div>
                    </div>
                    <div className="mb-3 mt-2">

                    <label style={{fontSize:'14px'}}>Phone Number</label>
                    <Controller
                      name='telephone'
                      control={control}
                      className="form-control"
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            value={editValue?.telephone}
                            enableSearch={true}
                            country={code}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />

                      {/* <Input
                        {...register("telephone")}
                        id="telephone"
                        type="text"
                        label="Telephone"
                        labelPlacement="outside"
                        placeholder="Enter your Telephone"
                      /> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div class="mb-3 mt-2">
                      <Input
                      isDisabled={companyID?.user?.role==='SUPERADMIN'}
                        {...register("imo")}
                        id="imo"
                        type="text"
                        label="IMO"
                        labelPlacement="outside"
                        placeholder="Enter your IMO"
                      />
                      <div className="error-msg">
                        {errors.imo?.message} {errName}
                      </div>
                    </div>
                    <div class="mb-3 mt-2">
                       <Textarea
                          {...register("address")}
                          variant="faded"
                          label="Address"
                          labelPlacement="outside"
                          placeholder="Enter your address"
                          name="address"
                        />
                    </div>
                    <div class="mb-3 mt-2">
                      <CustomSelect label="Status"   labelPlacement="outside"  name="status" register={register} handleSelectChange ={handleSelectChange} defaultSelectedKeys={[editValue?.status || "ACTIVE"]} options="statusOptions" />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
