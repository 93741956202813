
import React, { useEffect, useState } from "react";
import authQueries from "../../queries/authQueries";
import { userStore } from '../../store/userStore';
import { useNavigate } from "react-router-dom";
import { Pagination } from '@nextui-org/react';
import {Spinner} from "@nextui-org/react";
import { formatTimestamp } from '../../helpers/dateFormatter';
import RouteConstants from "../../Constants/RouteConstant";
import {
	Modal,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	useDisclosure,
  Button
  } from "@nextui-org/react";

const PublicIssueList = () => {

    const [result, setResult] = useState([]);
    const companyID = userStore();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [doc, setDoc]= useState('')
    const [isLoading, setIsLoading] = useState(false);
    const store = userStore();
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [whathappend, setWhathappened]=useState('')

    const InitCall =async ()=> {
        setIsLoading(true); 
        await issueList.mutateAsync({ 
            search: "", 
            status: "", 
            page: 0, 
            size: 10,
            accountStatus:store?.companyFilter?.selectedAccntStatus,
            category:store?.companyFilter?.selectedCategory,
            platform:store?.companyFilter?.selectedPlatform,
            reference:store?.companyFilter?.selectedReference
        });
    }

    useEffect(() => {
        
      let FilterValues={
          selectedDoc:'',
          selectedVessel:'',
          selectedAccntStatus:'',
          selectedCategory:'',
          selectedPlatform:'',
          selectedReference:''

  }
      store.setSelectedFilter(FilterValues);
      issueList.mutateAsync({ 
          search: "", 
          status: "", 
          page: 0, 
          size: 10, 
          accountStatus:'',
          category:'',
          platform:'',
          reference:''
          
      });
    }, []);

    
    useEffect(() => {
      InitCall();
      setDoc(store?.companyFilter?.selectedDoc)
    }, [store]);

    
    const issueList = authQueries.useComplaintePublicListMutation(
        (response) => {
            setResult(response?.data);
            setIsLoading(false)
        }
    );

    const ShipadminExport = () => {
      issueExport.mutateAsync(
        {
          search: "", 
          category:store?.companyFilter?.selectedCategory,
          platform:store?.companyFilter?.selectedPlatform,
          reference:store?.companyFilter?.selectedReference

        })
  }
    
    const issueExport = authQueries.useShipAdminComplaintNoodeReportMutation
    (
        (response) => {
            if(response.fileName){
                window.open(`${process.env.REACT_APP_BASE_URL}/api/report/nocode/complaintdownload/${response.fileName}`, '_blank');
            }
        }   
    );

      

  return (
    <div class="container-fluid">
    <div class="row">
    {isLoading ? 
      (
      <div className="loader" >
        <Spinner size="large" />
      </div>
      ) : (
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="dashboard-section-title">Issues with No Workplace Code <span class="data-count">{result?.totalItems}</span></h3>
                </div>
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                      <button   
                          className="btn btn-add btn-darkblue"
                          onClick={ShipadminExport}
                        >
                          <i className="icon-file-excel"></i> Export
                      </button>
                    
                    </div>
                </div>
            </div>
            
            
            <div class="table-wrpr">
                <div class="table-section">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="20">#</th>
                                <th width="100">Issue Name</th>
                                <th>Reference Number</th>
                                <th width="120">Reported Date</th>
                                <th width="150">vessel_name</th>
                                <th width="120">Ship Type</th>
                                <th width="250">Issue Type</th>
                                <th width="250">What Happened</th>
                                <th>Platform</th>
                                <th width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {result?.items?.map((item, index) => {
                            const categories = item?.categories?.filter(item => item.complaint_category_nocode && item.complaint_category_nocode.status === 'ACTIVE')
                            .map(item => item.category);
                            const categoryString = categories?.join(', ');
                          return(
                          <tr>
                            <td>{(index + 1) + result?.currentPage * 10}</td>
                            <td>Issue No {(index + 1) + result?.currentPage * 10}</td>
                            <td>{item?.reference_num}</td>
                            <td>{formatTimestamp(item?.created_at)}</td>										
                            <td>{item.vessel_name.slice(0,20)}</td>
                            <td>{item.shipType?.type}</td>
                            <td>{categoryString}</td>
                            <td data-bs-toggle="tooltip" onClick={()=>{ setWhathappened(item?.what_happened); onOpen()}} title={item.what_happened}>{item?.what_happened.slice(0,50)}<i class="icon-popout"></i></td>
                            <td>{item.platform==='ANDROID'?'MOB':'WEB'}</td>
                            <td class="action-links">
                                <a href="#" onClick={()=>{ navigate(RouteConstants.PUBLICISSUESVIEWURL+item.id);}} class="btn btn-view btn-round icon-eye"></a>
                            </td>
                          </tr>
                          
                          )})}
                          
                        </tbody>
                    </table>
                </div>  
            </div>
            
            <div class="pagination-wrpr">
                <Pagination  
                showControls
                total={result?.totalPages} 
                onChange={(e) => {
                issueList.mutateAsync({
                  search: '', 
                  page: e-1 , 
                  size:pageSize,
                  accountStatus:store?.companyFilter?.selectedAccntStatus,
                  category:store?.companyFilter?.selectedCategory,
                  platform:store?.companyFilter?.selectedPlatform,
                  reference:store?.companyFilter?.selectedReference
                },
                  setCurrentPage(e-1))
                }}
                initialPage={1}  
                />
            </div>
            
        </div>
         )}
    </div>


    
{/* What  Happened */}
<Modal size='2xl'  isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">What Happened</ModalHeader>
              <ModalBody>
                {whathappend}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>


</div>

  )
}

export default PublicIssueList