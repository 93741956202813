
import React, {useState, useEffect} from 'react'
import { formatTimestamp, formatCustomDate, calculateDaysTillToday } from '../../helpers/dateFormatter';
import { Button, Input } from '@nextui-org/react';
import { userStore } from '../../store/userStore';


export default function PublicIssueview({compliant, handleOpen, actioncategory, setActioncategory, setAcceptText, acceptText, handleAccept}) {

	const store  = userStore()

	const categories = compliant?.categories?.filter(item => item.complaint_category_nocode && item?.complaint_category_nocode?.status === 'ACTIVE')
  	.map(item => item.category);

	const categoryString = categories?.join(', ');
	const [history,setHistory] = useState([]);
	
	const options = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false,
		timeZone: 'UTC',
	  }
	  useEffect(() => {
		const fetchData = async () => {
		  if (compliant?.categories) {
			await filterComplaintData(compliant?.categories);
			console.log('history', history);
			if (history?.length > 0) {
				let highestDatetime = null;
				let objectWithHighestDatetime = null;
				for (const item of history) {
					const datetimeString = item.datetime;
					const datetime = new Date(datetimeString);
				
					if (!isNaN(datetime.getTime()) && (highestDatetime === null || datetime > highestDatetime)) {
						highestDatetime = datetime;
						objectWithHighestDatetime = item;
					}
				}
				if (objectWithHighestDatetime) {
					console.log("Object with the highest date-time:", new Date(objectWithHighestDatetime.datetime).toLocaleDateString('en-IN', options));
				} else {
					

					console.log("No valid date-time values found in the array.");
				}
			  setActioncategory(objectWithHighestDatetime);
			}
		  }
		};
	  
		fetchData();
	  }, [compliant]);



	  async function filterComplaintData(data) {
		const filteredData = data.map((item) => {
		  if (
			item?.complaint_category?.created_by !== null &&
			item?.complaint_category?.updated_by !== null &&
			item?.complaint_category?.status === 'ACTIVE'
		  ) {
			const formattedDate = new Date(item?.complaint_category?.updated_at);
	  
			setHistory((prevHistory) => [
			  ...prevHistory,
			  { actioncategory: "Category Updated", datetime: item.complaint_category.updated_at, by: item?.complaint_category?.updated_by },
			]);
	  
			return {
			  id: item.id,
			  updated_at: formattedDate.toLocaleDateString('en-US', options),
			  updated_by: item?.complaint_category?.updated_by,
			};
		  } else if (item?.complaint_category?.created_by !== null && item?.complaint_category?.status === 'ACTIVE') {
			const formattedDate = new Date(item.complaint_category.created_at);
	  
			setHistory((prevHistory) => [
			  ...prevHistory,
			  { actioncategory: "Category Created", datetime: item.complaint_category.created_at, by: item?.complaint_category?.created_by },
			]);
	  
			return {
			  id: item.id,
			  created_at: formattedDate.toLocaleDateString('en-IN', options),
			  created_by: item?.complaint_category?.created_by,
			};
		  } else if (item?.complaint_category?.created_by !== null && item?.complaint_category?.updated_by !== null && item?.complaint_category?.status === 'INACTIVE') {
			const formattedDate = new Date(item.complaint_category.updated_at);
	  
			setHistory((prevHistory) => [
			  ...prevHistory,
			  { actioncategory: "Category Removed", datetime: item.complaint_category.updated_at, by: item?.complaint_category?.updated_by },
			]);
		  }
		  // If neither condition is met, skip this item
		  return null;
		});
	  
		// Filter out the null values (items that didn't meet the conditions)
		return filteredData.filter((item) => item !== null);
	  }
	  

	  const handleDownload =(item)=> {
		window.open(item)
	  }

  return (
	<>
        <div class="col-md-12">
			<h5>Issue #{compliant?.reference_num}</h5>
			
			<div class="card">
				<div class="card-body">

					<div className="row">

						<div className='col-md-6'>
							<div class="detail-group">
								<label>Issue Reported on</label>
								<div> 
								{compliant?.created_at ? ( <p>{formatTimestamp(compliant.created_at)}</p>) : (null)} 
								</div>
							</div>
						</div> 

						<div className='col-md-6'>
							<div class="detail-group">
								<label>Number Of Days Open</label>
								<div> 
								{ compliant?.created_at ? ( <p>{calculateDaysTillToday(compliant.created_at)}</p>) : (null)} 
								</div>
							</div>
						</div> 

					</div>

					<div class="detail-group">
						<div class="label-and-button">
							<label>Issue Category Reported</label>
							{/* <Button
							color="primary" 
							size="sm" 
							radius="full"  
							onClick={handleOpen}  
							disabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === store?.user?.id && item?.isaccept === "NO")) ? true: false}
							className={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === store?.user?.id && item?.isaccept === "NO")) ? "bg-primary-200 cursor-not-allowed": "bg-primary"}	  
							>Assign New Category</Button> */}
						</div>
						<div><p>{categoryString} </p></div>
					</div>
					{compliant?.category_other !== null && (
						<div class="detail-group">
							<div class="label-and-button">
							<label>Other Issue Category Reported</label>
							{/* <Button
								color="primary" 
								size="sm" 
								radius="full"  
								onClick={handleOpen}  
								disabled={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === store?.user?.id && item?.isaccept === "NO")) ? true: false}
								className={compliant?.complaint_assigns?.find(item => (item?.app_user?.user_role?.roleid === 4 && item?.status === "ACTIVE" && item?.assignee === store?.user?.id && item?.isaccept === "NO")) ? "bg-primary-200 cursor-not-allowed": "bg-primary"}	  
							>Assign New Category</Button> */}
							</div>
							<div><p>{compliant?.category_other} </p></div>
						</div>
					)}


					<div class="detail-group">
						<label>Issue Related to</label>
						<div>
							<div className='flex gap-16 mt-4'>
								<p><b>Vessel Name: </b>{compliant?.vessel_name} - {compliant?.imo_num}</p>
								<p><b>Previous Port:</b> {compliant?.previous_port}</p>
								<p><b>Flag: </b>{compliant?.flag}</p>
								<p><b>Ship Type: </b>{compliant?.shipType?.type}</p>
								{compliant?.ship_type_other !== null && ( <p><b>Other Ship Type: </b>{compliant?.ship_type_other}</p>  )}
							</div>
							<div className='flex gap-16 mt-4'>
								<p><b>Technical Manager Name:</b> {compliant?.tech_manager_name}</p>
								<p><b>Technical Manager Email:</b>{compliant?.tech_manager_email}</p>
								<p><b>Technical Manager Phone:</b> {compliant?.tech_manager_phone}</p>							
							</div>
							<div className='flex gap-16 mt-4'>
								<p><b>Crew Member Name: </b>{compliant?.crew_manager_cmpny}</p>
								<p><b>Crew Member Email:</b> {compliant?.crew_manager_email}</p>
								<p><b>Crew Member Phone: </b>{compliant?.crew_manager_phone}</p>
							</div>
							<div className='flex gap-16 mt-4'>
								<p><b>Rank on Board: </b>{compliant?.rankOnBoard?.name}</p>
								{compliant?.rank_on_board_other !== null && ( <p><b>Other Rank on Board: </b>{compliant?.rank_on_board_other}</p>  )}
							</div>
							
						</div>
					</div>

					









					
					<div class="detail-group">
						<label class="mb-2">What Happened </label>
						<div class="what-happend-content">
							{compliant?.what_happened}
						</div>
					</div>
					
					<div class="detail-group">
						<label>What Caused It</label>
						<div>
							<p>{compliant?.what_caused}</p>
							
						</div>
					</div>
					
					<div class="detail-group">
						<label>What Needs to be done</label>
						<div>
							<p>{compliant?.what_need_tobe_done}</p>
							
						</div>
					</div>

					{compliant?.callback ==='YES'? 
					<div class="detail-group">
						<label>Reporter Request</label>
						<div>
							<p>Reporter has requested SHIPCONREP to make contact.</p>
						</div>
					</div>
					:null
					}
					
					<div class="detail-group">
						<label>Attachment</label>
						<div class="attachment-links">
								{compliant?.complaint_attachement_nocodes?.map((item, index)=> { 
									const urlObject = new URL(item?.file_name);
									const pathname = urlObject.pathname;
									const fileName = pathname.split('/').pop();

									return (
									(item?.file_type === "image/png" || item?.file_type === "image/jpg" || item?.file_type === "image/svg+xml" || item?.file_type === "image/jpeg") ?
									<div><a target='_blank' key={index} onClick={()=> {handleDownload(item?.file_name)}} style={{height:'130px', cursor:'pointer'}}>
										<div>
											<img src={item?.file_name} />
										</div>
									</a><p className='attachement-text'>{fileName}</p></div>:
									<div><a target='_blank' key={index} onClick={()=> {handleDownload(item?.file_name)}} style={{height:'130px', cursor:'pointer', color:'#fff'}}>
										<i class="icon-download8"></i>
									</a><p className='attachement-text'>{fileName}</p></div>
									// <button onClick={()=> {handleDownload(item?.file_name)}}>Download</button>
								) }
								)}
								{compliant?.complaint_attachement_nocodes?.length>0?null:<p>No Attachment</p>}
							{/* <a href="#" class="venobox audio-attachment">
								<i class="icon-volume_up"></i>
							</a>
							<a href="https://www.youtube.com/watch?v=jNE6bv-TAEk&ab_channel=CruisePassion" data-autoplay="true" data-vbtype="video" class="venobox video-attachment">
								<i class="icon-youtube"></i>
							</a> */}
							
						</div>
					</div>
					{compliant?.informant_callback_nocode?
					<div class="detail-group">
						<label>Callback Requested</label>
						<div>
							<div className='flex gap-16 mt-4'>
								<p><b>Phone Number: </b>{compliant?.informant_callback_nocode?.callback_phone}</p>
								<p><b>Whatsapp Number:</b> {compliant?.informant_callback_nocode?.callback_whatsapp}</p>
								<p><b>Callback Language: </b>{compliant?.informant_callback_nocode?.callback_language}</p>
								<p><b>CallBack Time: </b>{formatCustomDate(compliant?.informant_callback_nocode?.callback_time, options)}</p>
							</div>
						</div>
					</div>:
					<div class="detail-group">
					  <label>No Callback</label>
				     </div>
					
					}

				</div>
			</div>
			
		</div>
		
	</>	

  )}