import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import CustomSelect from "../../common/customSelect";
import {Spinner} from "@nextui-org/react";

export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
  handleSelectDoc,
  docholder,
  fleet,
  handleSelectFleet,
  user,
  handleSelectUser,
  isLoading,
  isModalLoading,
  vesselErr,
  vesselImoErr,
  companyID,
  docData
}) {
  const [data, setData] = useState(editValue);
  //useEffect(() => { setVal(editValue);  }, [editValue] )
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Modal  size="3xl"
    backdrop="blur"  isOpen={isOpen} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {editValue ? "Update Vessel" : "Add New Vessel"}
                {editValue?.status==='INACTIVE'?<p className="vesselnot">Please Activate the vessel to edit the veseel</p>:null}
              </ModalHeader>
              {isModalLoading?(<Spinner size="large" />):(
              <ModalBody>
              <div className="row">
                  <div className="col-md-6">

                    <div className="mb-3 mt-2">
                        <Input
                          isDisabled={editValue?.status==='INACTIVE'}
                            {...register("name")}
                            id="name"
                            type="text"
                            label="Vessel Name"
                            labelPlacement="outside"
                            placeholder="Enter your Vessel Name"
                            name="name"
                          />
                          <div className="error-msg">
                            {vesselErr?"⚠ Vessel name Already Exist":null}
                            {errors.name?.message} {errName}
                          </div>
                      </div>
                    <div className="mb-3 mt-2">
                          <Select
                              isDisabled={editValue?.status==='INACTIVE'}
                              label="Select Doc Holder" 
                              labelPlacement="outside"
                              defaultSelectedKeys={editValue?.doc_holder?.id ? [editValue.doc_holder?.id.toString()] : []}
                                placeholder="Select Doc Holder"
                                {...register("doc_holder_id", {
                                })}
                                onChange={(e) => handleSelectDoc(e)}
                                name="doc_holder_id"
                                >
                                {docholder.map((option) => (
                                  <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                  </SelectItem>
                                ))}
                            </Select>
                          <div className="error-msg">
                              {errors.doc_holder_id?.message} {errName}
                          </div>
                    </div>

                    <div className="mb-3 mt-2">
                        <Input
                            isDisabled={true}
                            {...register("doc_code")}
                            className="text-black"
                            id="doc_code"
                            type="text"
                            label="Doc Code"
                            labelPlacement="outside"
                            placeholder="Doc Code"
                            name="doc_code"
                          />
                    </div>

                    <div className="mb-3 mt-2">
                          <Select
                              isDisabled={editValue?.status==='INACTIVE'}
                              label="Select Account User" 
                              labelPlacement="outside"
                              defaultSelectedKeys={editValue?.app_user?.id ? [editValue.app_user?.id.toString()] : []}
                                placeholder="Select Account User"
                                {...register("user_id", {
                                })}
                                onChange={(e) => handleSelectUser(e)}
                                name="user_id"
                                >
                                {user?.map((option) => (
                                  <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                  </SelectItem>
                                ))}
                            </Select>
                          <div className="error-msg">
                              {errors.user_id?.message} {errName}
                          </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                      <div className="mb-3 mt-2">
                        <Input
                            isDisabled={editValue?.status==='INACTIVE'}
                            {...register("vessel_imo")}
                            id="vessel_imo"
                            type="text"
                            label="Vessel IMO"
                            labelPlacement="outside"
                            placeholder="Enter your Vessel IMO"
                            name="vessel_imo"
                          />
                          <div className="error-msg">
                            {vesselImoErr?"⚠ Vessel IMO Already Exist":null}
                            {errors.vessel_imo?.message} {errName}
                          </div>
                      </div>

                      <div className="mb-3 mt-2">
                        <Input
                            isDisabled={true}
                            {...register("doc_super_user")}
                           // value={docData?.doc_holder?.user_doc_relation?.app_user?.fname+' '+docData?.doc_holder?.user_doc_relation?.app_user?.lname}
                            id="doc_super_user"
                            type="text"
                            label="Doc Super User"
                            labelPlacement="outside"
                            placeholder="Doc Super User"
                            name="doc_super_user"
                          />
                            <div className="error-msg">
                              {errors.doc_super_user?.message} {errName}
                          </div>
                      </div>

                      <div className="mb-3 mt-2">
                          <Select
                              isDisabled={editValue?.status==='INACTIVE'}
                              label="Select Fleet" 
                              labelPlacement="outside"
                              defaultSelectedKeys={editValue?.fleet?.id ? [editValue.fleet?.id.toString()] : []}
                                placeholder="Select Fleet"
                                {...register("fleet_id", {
                                })}
                                onChange={(e) => handleSelectFleet(e)}
                                name="fleet_id"
                                >
                                {fleet.map((option) => (
                                  <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                  </SelectItem>
                                ))}
                            </Select>
                          <div className="error-msg">
                              {errors.fleet_id?.message} {errName}
                          </div>
                    </div>

                      <div className="mb-3 mt-2">
                        <CustomSelect
                          name="status"
                          label="Select Status"
                          register={register}
                          handleSelectChange={handleSelectChange}
                          defaultSelectedKeys={[editValue?.status || "ACTIVE"]}
                          options="statusOptions"
                        />
                    </div>
                  </div>
                </div>
              </ModalBody>
                  )}
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
