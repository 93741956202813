
import { useMutation } from "react-query";
import authService from "../services/authervice";

const useLoginMutation = (onLoginSuccess) => {
    return useMutation(authService.login, {
      onSuccess: (data) => {
        onLoginSuccess(data);
      },
    });
};

const useShipTypeListMutation = (onShipTypeList) => {
  return useMutation(authService.shipTypeList, {
    onSuccess: (data) => {
      onShipTypeList(data);
    },
  });
};

const useShipTypeAddMutation = (onShipTypeAdd) => {
  return useMutation(authService.shipTypeAdd, {
    onSuccess: (data) => {
      onShipTypeAdd(data);
    },
  });
};

const useShipTypeUpdateMutation = (onShipTypeUpdate) => {
  return useMutation(authService.shipTypeUpdate, {
    onSuccess: (data) => {
      onShipTypeUpdate(data);
    },
  });
};



const useShipTypeMutationDelete = (onShipTypeData) => {
  return useMutation(authService.deleteShipType, {
    onSuccess: (data) => {
      onShipTypeData(data);
    },
  });
};

const useRankonboardListMutation = (onRankList) => {
  return useMutation(authService.rankList, {
    onSuccess: (data) => {
      onRankList(data);
    },
  });
};

const useRankAddMutation = (onRankAdd) => {
  return useMutation(authService.rankAdd, {
    onSuccess: (data) => {
      onRankAdd(data);
    },
  });
};

const useRankUpdateMutation = (onRankUpdate) => {
  return useMutation(authService.rankUpdate, {
    onSuccess: (data) => {
      onRankUpdate(data);
    },
  });
};

const useRankMutationDelete = (onRankData) => {
  return useMutation(authService.deleteRank, {
    onSuccess: (data) => {
      onRankData(data);
    },
  });
};
//category
const useCategoryListMutation = (onCategoryList) => {
  return useMutation(authService.categoryList, {
    onSuccess: (data) => {
      onCategoryList(data);
    },
  });
};

const useCategoryAddMutation = (onCategoryAdd) => {
  return useMutation(authService.categoryAdd, {
    onSuccess: (data) => {
      onCategoryAdd(data);
    },
  });
};

const useCategoryUpdateMutation = (onCategoryUpdate) => {
  return useMutation(authService.categoryUpdate, {
    onSuccess: (data) => {
      onCategoryUpdate(data);
    },
  });
};

const useCategoryMutationDelete = (onCategoryData) => {
  return useMutation(authService.categoryDelete, {
    onSuccess: (data) => {
      onCategoryData(data);
    },
  });
};

//Country
const useCountryListMutation = (onCountryList) => {
  return useMutation(authService.countryList, {
    onSuccess: (data) => {
      onCountryList(data);
    },
  });
};

const useCountryAddMutation = (onCountryAdd) => {
  return useMutation(authService.countryAdd, {
    onSuccess: (data) => {
      onCountryAdd(data);
    },
  });
};

const useCountryUpdateMutation = (onCountryUpdate) => {
  return useMutation(authService.countryUpdate, {
    onSuccess: (data) => {
      onCountryUpdate(data);
    },
  });
};

const useCountryMutationDelete = (onCountryData) => {
  return useMutation(authService.countryDelete, {
    onSuccess: (data) => {
      onCountryData(data);
    },
  });
};

//Company
const useCompanyListMutation = (onCompanyList) => {
  return useMutation(authService.companyList, {
    onSuccess: (data) => {
      onCompanyList(data);
    },
  });
};

const useCompanyAddMutation = (onCompanyAdd) => {
  return useMutation(authService.companyAdd, {
    onSuccess: (data) => {
      onCompanyAdd(data);
    },
  });
};

const useCompanyUpdateMutation = (onCompanyUpdate) => {
  return useMutation(authService.companyUpdate, {
    onSuccess: (data) => {
      onCompanyUpdate(data);
    },
  });
};

const useCompanyMutationDelete = (onCompanyData) => {
  return useMutation(authService.companyDelete, {
    onSuccess: (data) => {
      onCompanyData(data);
    },
  });
};

const useCompanyMutationView = (onCompanyData) => {
  return useMutation(authService.companyView, {
    onSuccess: (data) => {
      onCompanyData(data);
    },
  });
};

const useImageUploadSingleMutation = (fileData) => {
  return useMutation(authService.imageUploadSingle, {
    onSuccess: (data) => {
      fileData(data);
    },
  });
};

//Office
const useOfficeListMutation = (onOfficeList) => {
  return useMutation(authService.officeList, {
    onSuccess: (data) => {
      onOfficeList(data);
    },
  });
};

const useOfficeAddMutation = (onOfficeAdd) => {
  return useMutation(authService.officeAdd, {
    onSuccess: (data) => {
      onOfficeAdd(data);
    },
  });
};

const useOfficeUpdateMutation = (onOfficeUpdate) => {
  return useMutation(authService.officeUpdate, {
    onSuccess: (data) => {
      onOfficeUpdate(data);
    },
  });
};

const useOfficeMutationDelete = (onOfficeData) => {
  return useMutation(authService.officeDelete, {
    onSuccess: (data) => {
      onOfficeData(data);
    },
  });
};


//Doc Holder
const useDocListMutation = (onDocList) => {
  return useMutation(authService.docList, {
    onSuccess: (data) => {
      onDocList(data);
    },
  });
};

const useDocAddMutation = (onDocAdd) => {
  return useMutation(authService.docAdd, {
    onSuccess: (data) => {
      onDocAdd(data);
    },
  });
};

const useDocUpdateMutation = (onDocUpdate) => {
  return useMutation(authService.docUpdate, {
    onSuccess: (data) => {
      onDocUpdate(data);
    },
  });
};

const useDocMutationDelete = (onDocData) => {
  return useMutation(authService.docDelete, {
    onSuccess: (data) => {
      onDocData(data);
    },
  });
};

const useDocVesselListMutation = (onDocVesselList) => {
  return useMutation(authService.docVesselList, {
    onSuccess: (data) => {
      onDocVesselList(data);
    },
  });
};

//User
const useUserListMutation = (onUserList) => {
  return useMutation(authService.userList, {
    onSuccess: (data) => {
      onUserList(data);
    },
  });
};

const useUserAddMutation = (onUserAdd) => {
  return useMutation(authService.userAdd, {
    onSuccess: (data) => {
      onUserAdd(data);
    },
  });
};

const useUserUpdateMutation = (onUserUpdate) => {
  return useMutation(authService.userUpdate, {
    onSuccess: (data) => {
      onUserUpdate(data);
    },
  });
};

const useUserMutationDelete = (onUserData) => {
  return useMutation(authService.userDelete, {
    onSuccess: (data) => {
      onUserData(data);
    },
  });
};

const useAccountUserListMutation = (onAccountUserList) => {
  return useMutation(authService.accountuserList, {
    onSuccess: (data) => {
      onAccountUserList(data);
    },
  });
};

const useUserDocListMutation = (onUserDocList) => {
  return useMutation(authService.userdocList, {
    onSuccess: (data) => {
      onUserDocList(data);
    },
  });
};

//roles
const useRoleListMutation = (onRoleList) => {
  return useMutation(authService.roleList, {
    onSuccess: (data) => {
      onRoleList(data);
    },
  });
};

const useUserRoleListMutation = (onUserRoleList) => {
  return useMutation(authService.userroleList, {
    onSuccess: (data) => {
      onUserRoleList(data);
    },
  });
};

//Fleet
const useFleetListMutation = (onFleetList) => {
  return useMutation(authService.fleetList, {
    onSuccess: (data) => {
      onFleetList(data);
    },
  });
};

const useFleetAddMutation = (onFleetAdd) => {
  return useMutation(authService.fleetAdd, {
    onSuccess: (data) => {
      onFleetAdd(data);
    },
  });
};

const useFleetUpdateMutation = (onFleetUpdate) => {
  return useMutation(authService.fleetUpdate, {
    onSuccess: (data) => {
      onFleetUpdate(data);
    },
  });
};

const useFleetMutationDelete = (onFleetData) => {
  return useMutation(authService.fleetDelete, {
    onSuccess: (data) => {
      onFleetData(data);
    },
  });
};

//vessel
const useVesselListMutation = (onVesselList) => {
  return useMutation(authService.vesselList, {
    onSuccess: (data) => {
      onVesselList(data);
    },
  });
};

const useVesselAddMutation = (onVesselAdd) => {
  return useMutation(authService.vesselAdd, {
    onSuccess: (data) => {
      onVesselAdd(data);
    },
  });
};

const useVesselUpdateMutation = (onVesselUpdate) => {
  return useMutation(authService.vesselUpdate, {
    onSuccess: (data) => {
      onVesselUpdate(data);
    },
  });
};

const useVesselMutationDelete = (onVesselData) => {
  return useMutation(authService.vesselDelete, {
    onSuccess: (data) => {
      onVesselData(data);
    },
  });
}

const useVesselViewMutation = (onVesselView) => {
  return useMutation(authService.vesselView, {
    onSuccess: (data) => {
      onVesselView(data);
    },
  });
};


//compliant
const useCompliantListMutation = (onCompliantList) => {
  return useMutation(authService.compliantlList, {
    onSuccess: (data) => {
      onCompliantList(data);
    },
  });
};

const useComplaintIssuesMutation = (onData) => {
  return useMutation(authService.complaintListIssues, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useCompliantViewtMutation = (onCompliantView) => {
  return useMutation(authService.compliantlView, {
    onSuccess: (data) => {
      onCompliantView(data);
    },
  });
};

const useAssignCategoryMutation = (onAssigncategory) => {
  return useMutation(authService.assignCategory, {
    onSuccess: (data) => {
      onAssigncategory(data);
    },
  });
};

const useContactInformantMutation = (onContactInformant) => {
  return useMutation(authService.contactInformant, {
    onSuccess: (data) => {
      onContactInformant(data);
    },
  });
};

const useSendResponseMutation = (onSendResponse) => {
  return useMutation(authService.sendResponse, {
    onSuccess: (data) => {
      onSendResponse(data);
    },
  });
};

const useScheduleLaterMutation = (onSchedule) => {
  return useMutation(authService.scheduleLater, {
    onSuccess: (data) => {
      onSchedule(data);
    },
  });
};

const useLessonMutation = (onLesson) => {
  return useMutation(authService.lessonlearned, {
    onSuccess: (data) => {
      onLesson(data);
    },
  });
};
//issue count
const useCompliantCountMutation = (onCompliantCount) => {
  return useMutation(authService.compliantCount, {
    onSuccess: (data) => {
      onCompliantCount(data);
    },
  });
};

const useCompliantAssignedCountMutation = (onCompliantCount) => {
  return useMutation(authService.compliantAssignedCount, {
    onSuccess: (data) => {
      onCompliantCount(data);
    },
  });
};

//company Data count
const useCompanyDataCountMutation = (onCompanydataCount) => {
  return useMutation(authService.companyDataCount, {
    onSuccess: (data) => {
      onCompanydataCount(data);
    },
  });
};

const useAccountUserListingDocMutation = (onData) => {
  return useMutation(authService.accountuserDocList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useFullAccountUserListingDocMutation = (onData) => {
  return useMutation(authService.accountuserFullDocList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAccountUserForVesselListingDocMutation = (onData) => {
  return useMutation(authService.accountuserForVesselDocWiseList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useComplaintReassignMutation = (onData) => {
  return useMutation(authService.complaintReassign, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useComplaintAcceptMutation = (onData) => {
  return useMutation(authService.complaintAccept, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAccntUserListMutation = (onAccntUserList) => {
  return useMutation(authService.accntuserList, {
    onSuccess: (data) => {
      onAccntUserList(data);
    },
  });
};

const useUserExistMutation = (onUserExist) => {
  return useMutation(authService.userExist, {
    onSuccess: (data) => {
      onUserExist(data);
    },
  });
};

const useVesselExistMutation = (onVesselExist) => {
  return useMutation(authService.vesselExist, {
    onSuccess: (data) => {
      onVesselExist(data);
    },
  });
};

const useVesselImoExistMutation = (onVesselImoExist) => {
  return useMutation(authService.vesselImoExist, {
    onSuccess: (data) => {
      onVesselImoExist(data);
    },
  });
};

const useComplaintePublicListMutation = (onData) => {
  return useMutation(authService.compliantPublicList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useComplaintePublicViewMutation = (onData) => {
  return useMutation(authService.compliantPublicView, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useUserviewMutation = (onUserview) => {
  return useMutation(authService.userView, {
    onSuccess: (data) => {
      onUserview(data);
    },
  });
};


const useComplaintSuperUserListMutation = (onUserview) => {
  return useMutation(authService.complaintListSuperuserIssues, {
    onSuccess: (data) => {
      onUserview(data);
    },
  });
};

const useSendCancellation = (onUserview) => {
  return useMutation(authService.sendCancellation, {
    onSuccess: (data) => {
      onUserview(data);
    },
  });
};


const useVesselReportList = (onUserview) => {
  return useMutation(authService.vesselReportList, {
    onSuccess: (data) => {
      onUserview(data);
    },
  });
};

const useVesselStatusReportList = (onUserview) => {
  return useMutation(authService.vesselStatusReportList, {
    onSuccess: (data) => {
      onUserview(data);
    },
  });
};

const useVesselExport = (onUserview) => {
  return useMutation(authService.vesselReportExport, {
    onSuccess: (data) => {
      onUserview(data);
    },
  });
};

const useRequestDemo = (onDemoRequest) => {
  return useMutation(authService.requestDemo, {
    onSuccess: (data) => {
      onDemoRequest(data);
    },
  });
};

const useContactus = (onContact) => {
  return useMutation(authService.contactUs, {
    onSuccess: (data) => {
      onContact(data);
    },
  });
};

const useForgotpass = (onForgotpass) => {
  return useMutation(authService.forgotPass, {
    onSuccess: (data) => {
      onForgotpass(data);
    },
  });
};

const useResetpass = (onResetpass) => {
  return useMutation(authService.resetPass, {
    onSuccess: (data) => {
      onResetpass(data);
    },
  });
};

const useCategoryChart = (onCategorychart) => {
  return useMutation(authService.categoryChart, {
    onSuccess: (data) => {
      onCategorychart(data);
    },
  });
};

const useVesselChart = (onVesselchart) => {
  return useMutation(authService.vesselChart, {
    onSuccess: (data) => {
      onVesselchart(data);
    },
  });
};

const useCategoryReport = (onCategoryReport) => {
  return useMutation(authService.CategoryReport, {
    onSuccess: (data) => {
      onCategoryReport(data);
    },
  });
};

const useVesselcompanyReport = (onVesselcompanyReport) => {
  return useMutation(authService.VesselcompanyReport, {
    onSuccess: (data) => {
      onVesselcompanyReport(data);
    },
  });
};

const usePlatformChart = (onPlatformchart) => {
  return useMutation(authService.platformChart, {
    onSuccess: (data) => {
      onPlatformchart(data);
    },
  });
};

const useShipType = (onShipytpe) => {
  return useMutation(authService.shipTypeChart, {
    onSuccess: (data) => {
      onShipytpe(data);
    },
  });
};

const useRankchart = (onRankchart) => {
  return useMutation(authService.rankChart, {
    onSuccess: (data) => {
      onRankchart(data);
    },
  });
};

const useHelp = (onHelp) => {
  return useMutation(authService.help, {
    onSuccess: (data) => {
      onHelp(data);
    },
  });
};

const useDemoListMutation = (onDemoList) => {
  return useMutation(authService.demoList, {
    onSuccess: (data) => {
      onDemoList(data);
    },
  });
};

const useCompanycountListMutation = (onCompanyList) => {
  return useMutation(authService.companyCountList, {
    onSuccess: (data) => {
      onCompanyList(data);
    },
  });
};

const useSurveyReportMutation = (onSurveyReport) => {
  return useMutation(authService.surveyReport, {
    onSuccess: (data) => {
      onSurveyReport(data);
    },
  });
};


const useComplaintReportMutation = (onComplaintReport) => {
  return useMutation(authService.compliantlExport, {
    onSuccess: (data) => {
      onComplaintReport(data);
    },
  });
};

const useCompanyReportMutation = (oncompanyComplaintReport) => {
  return useMutation(authService.companyExport, {
    onSuccess: (data) => {
      oncompanyComplaintReport(data);
    },
  });
};

const useCategoryReportMutation = (oncategoryReport) => {
  return useMutation(authService.categoryExport, {
    onSuccess: (data) => {
      oncategoryReport(data);
    },
  });
};

const useCategoryNocodeReportMutation = (oncategorynocodeReport) => {
  return useMutation(authService.categoryNocode, {
    onSuccess: (data) => {
      oncategorynocodeReport(data);
    },
  });
};

const useRankOnboardNocodeReportMutation = (onrankonboardnocodeReport) => {
  return useMutation(authService.rankonboardNocode, {
    onSuccess: (data) => {
      onrankonboardnocodeReport(data);
    },
  });
};

const useTotalReport = (onTotalReport) => {
  return useMutation(authService.TotalReport, {
    onSuccess: (data) => {
      onTotalReport(data);
    },
  });
};

const useProfileList = (onProfileData) => {
  return useMutation(authService.ProifileAPI, {
    onSuccess: (data) => {
      onProfileData(data);
    },
  });
};

const useShipAdminComplaintReportMutation = (onComplaintReport) => {
  return useMutation(authService.shipadmincompliantlExport, {
    onSuccess: (data) => {
      onComplaintReport(data);
    },
  });
};

const useShipAdminComplaintNoodeReportMutation = (onComplaintReport) => {
  return useMutation(authService.shipadmincompliantNocodelExport, {
    onSuccess: (data) => {
      onComplaintReport(data);
    },
  });
};



const authQueries = {
  useLoginMutation,
  useShipTypeListMutation,
  useShipTypeAddMutation,
  useShipTypeUpdateMutation,
  useShipTypeMutationDelete,
  useRankonboardListMutation,
  useRankAddMutation,
  useRankUpdateMutation,
  useRankMutationDelete,
  useCategoryListMutation,
  useCategoryAddMutation,
  useCategoryUpdateMutation,
  useCategoryMutationDelete,
  useCountryListMutation,
  useCountryAddMutation,
  useCountryUpdateMutation,
  useCountryMutationDelete,
  useCompanyListMutation,
  useCompanyAddMutation,
  useCompanyUpdateMutation,
  useCompanyMutationDelete,
  useCompanyMutationView,
  useImageUploadSingleMutation,
  useOfficeListMutation,
  useOfficeAddMutation,
  useOfficeUpdateMutation,
  useOfficeMutationDelete,
  useDocListMutation,
  useDocAddMutation,
  useDocUpdateMutation,
  useDocMutationDelete,
  useUserListMutation,
  useUserAddMutation,
  useUserUpdateMutation,
  useUserMutationDelete,
  useRoleListMutation,
  useUserRoleListMutation,
  useFleetListMutation,
  useFleetAddMutation,
  useFleetUpdateMutation,
  useFleetMutationDelete,
  useVesselListMutation,
  useVesselAddMutation,
  useVesselUpdateMutation,
  useVesselMutationDelete,
  useAccountUserListMutation,
  useCompliantListMutation,
  useComplaintIssuesMutation,
  useUserDocListMutation,
  useCompliantViewtMutation,
  useAssignCategoryMutation,
  useContactInformantMutation,
  useSendResponseMutation,
  useScheduleLaterMutation,
  useLessonMutation,
  useCompliantCountMutation,
  useCompliantAssignedCountMutation,
  useCompanyDataCountMutation,
  useAccountUserListingDocMutation,
  useComplaintReassignMutation,
  useAccntUserListMutation,
  useUserExistMutation,
  useVesselExistMutation,
  useVesselImoExistMutation,
  useComplaintAcceptMutation,
  useComplaintePublicListMutation,
  useComplaintePublicViewMutation,
  useComplaintSuperUserListMutation,
  useUserviewMutation,
  useSendCancellation,
  useVesselReportList,
  useVesselExport,
  useVesselStatusReportList,
  useRequestDemo,
  useForgotpass,
  useResetpass,
  useCategoryChart,
  useVesselChart,
  useCategoryReport,
  useVesselcompanyReport,
  usePlatformChart,
  useShipType,
  useRankchart,
  useHelp,
  useFullAccountUserListingDocMutation,
  useDocVesselListMutation,
  useAccountUserForVesselListingDocMutation,
  useVesselViewMutation,
  useContactus,
  useDemoListMutation,
  useCompanycountListMutation,
  useSurveyReportMutation,
  useComplaintReportMutation,
  useCompanyReportMutation,
  useCategoryReportMutation,
  useCategoryNocodeReportMutation,
  useRankOnboardNocodeReportMutation,
  useTotalReport,
  useProfileList,
  useShipAdminComplaintReportMutation,
  useShipAdminComplaintNoodeReportMutation

};

export default authQueries;
