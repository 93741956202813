import React, {useEffect, useState} from "react";
import { Routes, Route, BrowserRouter, Navigate, useLocation  } from "react-router-dom";
import Login from "../components/Login/Login";
import ShipTypeList from "../components/Masters/shipType/shipTypeList";
import RouteConstants from "../Constants/RouteConstant";
import Sidebar from "../components/common/Layout/Sidebar";
import RankonBoardList from "../components/Masters/rankonboard/rankonBoardList";
import CategoryList from "../components/Masters/category/categoryList";
import CountryList from "../components/Masters/country/countryList";
import CompanyList from "../components/Masters/company/companyList";
import OfficeList from "../components/Masters/offfice/officeList";
import Dashboard from "../components/dashboard/Dashboard";
import ShipAdmin from "../components/dashboard/shipAdmin/ship-admin-Dashboard";
import DocHolderList from "../components/Masters/docHolder/docHolderList";
import UserList from "../components/Masters/user/userList";
import FleetList from "../components/Masters/fleet/fleetList";
import VesselList from "../components/Masters/vessel/vesselList";
import CompanyDash from "../components/dashboard/companyAdmin/companyDash";
import Compliantvew from "../components/compliant/compliantVew";
import PublicIssueList from "../components/compliant/PublicIssueList";
import PublicIssueview from "../components/compliant/PublicIssueView";
import PublicComplaintView from "../components/compliant/PublicComplaintView";
import VesselReport from "../components/reports/vesselReport";
import { userStore } from '../store/userStore';
import ReportDash from "../components/reports/reportdash";
import Site from "../site/site";
import SetNewPassword from "../components/forgotpass.js/forgotpas";
import CategoryPiechart from "../components/reports/charts/vesselChart";
import NocodeReport from "../components/reports/nocode/nocodeDash";
import About from "../site/about";
import Faq from "../site/faq";
import EFaq from "../pages/faq";
import PrivacyPolicy from "../site/privacy-policy";
import DemoList from "../components/demo/demolist";
import SurveyReport from "../components/reports/surveyReport";
import CategoryReport from "../components/reports/categoryreport";
import VesselcompanyReport from "../components/reports/vesselcompanyReport";
import CategoryNcodeReport from "../components/reports/categoryNocode";
import RankonboardNcodeReport from "../components/reports/RankonBoardreport";
import TotalReport from "../components/reports/totalreport";
import Profile from "../components/profiles/profile";
import authQueries from "../queries/authQueries";


const AppRoutes = () => {


  const location = useLocation();
  const companydata = userStore();
  
  const fullUrl = location.pathname + location.search;

    // Create a URLSearchParams object from the query string
  const searchParams = new URLSearchParams(fullUrl.split('?')[1]);

    // Get the value of the 'companyid' parameter from the URLSearchParams object
  const companyId = searchParams.get('companyid');


  const authGuard = (component) => {
    const accessToken = userStore.getState().user.jwttoken;
    if (accessToken) {
        if(companyId && !companydata?.companyData?.company){
          if(companydata?.user?.company===companyId){
           mutateAsync(companyId).then((response) => {
            companydata.setCompanyData({ company: companyId  })
            companydata.setCompany({company:response.data});   
            return component;   
        });
      } else if(companydata?.user?.role==='SHIPCONREPADMIN'){

        mutateAsync(companyId).then((response) => {
          companydata.setCompanyData({ company: companyId  })
          companydata.setCompany({company:response.data});   
          return component;   
      });
      }
      }else{
        return component;
      }
    } else {
      return <Login />;
    }
  }



  const accessToken = userStore.getState().user.jwttoken

  const [redirectPath, setRedirectPath] = useState(null);
  const store = userStore.getState().user;

  const urlString = location.pathname;


  useEffect(() => {


    mutateAsync(companyId)

    if(!accessToken){
    // Store the current location in local storage
    localStorage.setItem("redirectPath", location.pathname);
    }

  }, []);

 
  //const { mutateAsync } = authQueries.useCompanyMutationView();


  const { mutateAsync } = authQueries.useCompanyMutationView((response) => {
  });

  useEffect(async () => {
    // Retrieve the stored location from local storage
    const storedPath = localStorage.getItem("redirectPath");
    if (storedPath) {
      // Clear the stored location from local storage
      localStorage.removeItem("redirectPath");
      setRedirectPath(fullUrl);
    }
  }, []);




  return (
    <>

      {redirectPath && (
        <Navigate
          to={redirectPath}
          replace
        />
      )}

    <Routes>
      <Route exact path={RouteConstants.ROOT} element={<Site />} />
      <Route exact path={RouteConstants.ABOUT} element={<About />} />
      <Route exact path={RouteConstants.FAQ} element={<Faq />} />
      <Route exact path={RouteConstants.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route exact path={RouteConstants.FORGOTPASS} element={<SetNewPassword />} />
      <Route exact path={RouteConstants.LOGIN} element={<Login />} />
      <Route exact path={RouteConstants.ROOT}  element={authGuard(<Sidebar />)} >
      <Route
          exact
          path={RouteConstants.DASHBOARD}
          element={authGuard(<Dashboard />)}
        />
      
        <Route
          exact
          path={RouteConstants.SHIPADMINDASH}
          element={authGuard(<ShipAdmin />)}
        />
        <Route
          exact
          path={RouteConstants.SHIPTYPELIST}
          element={authGuard(<ShipTypeList />)}
        />
        <Route
          exact
          path={RouteConstants.RANKLIST}
          element={authGuard(<RankonBoardList />)}
        />
         <Route
          exact
          path={RouteConstants.CATEGORY}
          element={authGuard(<CategoryList />)}
        />
        <Route
          exact
          path={RouteConstants.COUNTRY}
          element={authGuard(<CountryList />)}
        />
          <Route
          exact
          path={RouteConstants.COMPANY}
          element={authGuard(<CompanyList />)}
        />
         <Route
          exact
          path={RouteConstants.OFFICE}
          element={authGuard(< OfficeList />)}
        />
         <Route
          exact
          path={RouteConstants.DOC}
          element={authGuard(< DocHolderList />)}
        />
        <Route
          exact
          path={RouteConstants.USER}
          element={authGuard(< UserList />)}
        />
        <Route
          exact
          path={RouteConstants.FLEET}
          element={authGuard(< FleetList />)}
        />
        <Route
          exact
          path={RouteConstants.VESSEL}
          element={authGuard(< VesselList />)}
        />
        <Route
          exact
          path={RouteConstants.ISSUES}
          element={authGuard(< CompanyDash />)}
        />
        <Route
          exact
          path={RouteConstants.COMPLIANTVIEW}
          element={ authGuard(< Compliantvew />) }
        />
        <Route
          exact
          path={RouteConstants.PUBLICISSUES}
          element={<PublicIssueList />}
        />
        <Route
          exact
          path={RouteConstants.PUBLICISSUESVIEW}
          element={<PublicComplaintView />}
        />
        <Route
          exact
          path={RouteConstants.REPORT}
          element={authGuard(< VesselReport />)}
        />
        <Route
          exact
          path={RouteConstants.CATEGORYREPORT}
          element={authGuard(< CategoryReport />)}
        />
        <Route
          exact
          path={RouteConstants.VESSELCOMPANYREPORT}
          element={authGuard(< VesselcompanyReport />)}
        />
        <Route
          exact
          path={RouteConstants.REPORTDASH}
          element={authGuard(< ReportDash />)}
        />
        <Route
          exact
          path={RouteConstants.VESSELCHART}
          element={authGuard(<CategoryPiechart />)}
        />
        <Route
          exact
          path={RouteConstants.NOCODEREPORT}
          element={authGuard(<NocodeReport />)}
        />
        <Route
          exact
          path={RouteConstants.EMPLOYERFAQ}
          element={authGuard(<EFaq />)}
        />
         <Route
          exact
          path={RouteConstants.DEMO}
          element={authGuard(<DemoList />)}
        />
        <Route
          exact
          path={RouteConstants.SURVEYREPORT}
          element={authGuard(<SurveyReport />)}
        />
        <Route
          exact
          path={RouteConstants.CATEGORYNOCODE}
          element={authGuard(<CategoryNcodeReport />)}
        />
        <Route
          exact
          path={RouteConstants.RANKONBOARDNOCODE}
          element={authGuard(<RankonboardNcodeReport />)}
        />
         <Route
          exact
          path={RouteConstants.TOTALREPORT}
          element={authGuard(< TotalReport />)}
        />
        <Route
          exact
          path={RouteConstants.PROFILE}
          element={authGuard(< Profile />)}
        />
      </Route>
    </Routes>
    </>
  );
};

export default AppRoutes;
