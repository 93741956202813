import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useDisclosure } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../../store/userStore';
import RouteConstants from "../../../Constants/RouteConstant";


const UserList = () => {

  const validationSchema = yup.object({
    username: yup
      .string("⚠ Enter Username")
      .required("⚠ Username is required")
      .min(2, "⚠ Username must be more than 2 characters"),
    pass: yup
      .string("⚠ Enter Password")
      .required("⚠ Password is required")
      .min(4, "⚠ Password must be more than 4 characters"),
    fname: yup
      .string("⚠ Enter Firstname")
      .required("⚠ Firstname is required")
      .min(2, "⚠ Firstname must be more than 2 characters"),
    lname: yup
      .string("⚠ Enter Lastname")
      .required("⚠ Lastname is required")
      .min(1, "⚠ Lastname must be more than 1 character"),
    email: yup
      .string("⚠ Enter Email")
      .required("⚠ Email is required")
      .email("⚠ Enter a valid email address"),
    role: yup
      .string("⚠ Select User Role")
      .required("⚠ User Role is required"),
    docId: yup.string().when('role', {
        is: (value) => value === '3',
        then: (schema) => schema.required('⚠ Doc Holder is Required'),
        otherwise: (schema) => schema,
      }),
  });
  
  




  const [result, setResult] = useState([]);
  const [role, setRole] = useState([]);
  const [office, setOffice] = useState([]);
  const [doc, setdoc] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10);
  const [location, setLocation] = useState("");
  const [userexistErr, setUserexistErr] = useState(false);
  const companyID = userStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isbuttonLoading, setIsbuttonLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [code, setCode] = useState('in')

  const [selectedRole, setSelectedRole] = useState(editValue?.user_role?.roleid ? editValue?.user_role?.roleid : '')

  useEffect(()=>{
    setSelectedRole(editValue?.user_role?.roleid ? editValue?.user_role?.roleid : '')
  },[isOpen])

  useEffect(() => {
    mutateAsync({ search: companyID?.companyFilter?.selectedUserkey, status: companyID?.companyFilter?.selectedAccntStatus, role:companyID?.companyFilter?.selectedRole,  page: 0, size: 10 , schema:companyID?.companyData?.company});
  }, [companyID]);



  useEffect(() => {
    let FilterValues={
      selectedDoc:'',
      selectedVessel:'',
      selectedAccntStatus:'',
      selectedCategory:'',
      selectedPlatform:'',
      selectedReference:'',
      selectedVesselKey:'',
      selectedFleet:'',
      selectedUserkey:'',
      selectedRole:'',  
    }
      companyID.setSelectedFilter(FilterValues);
    mutateAsync({ search: "", status: "", page: 0, size: 10 , schema:companyID?.companyData?.company});
  }, []);
  
  useEffect(() => {
    if(parseInt(selectedRole)===4){
      fulldocListing.mutateAsync({schema:companyID?.companyData?.company});
    }else if(parseInt(selectedRole)===3){
      editValue? docListing.mutateAsync({id:editValue.id,schema:companyID?.companyData?.company}): docListing.mutateAsync({id:"",schema:companyID?.companyData?.company});
    }
  }, [selectedRole]);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useUserListMutation((response) => {
    setResult(response.data);
  });

  const InitCall =async (data)=> {
    console.log("(editValue?.user_role?.roleid",data?.user_role?.roleid)
    await officeListing.mutateAsync({ search: "", status: "", page: 0, size: 1000 , schema:companyID?.companyData?.company});
    await roleListing.mutateAsync();
    //await fulldocListing.mutateAsync({schema:companyID?.companyData?.company});
    if(data){
          if(parseInt(data?.user_role?.roleid)===3)
          {
            await docListing.mutateAsync({id:data.id,schema:companyID?.companyData?.company})
          }
          else if(parseInt(data?.user_role?.roleid)===4)
          {
            await fulldocListing.mutateAsync({schema:companyID?.companyData?.company});
          }
    }else{
      await docListing.mutateAsync({id:"",schema:companyID?.companyData?.company});
    }
    //data?await docListing.mutateAsync({id:data.id,schema:companyID?.companyData?.company}):await docListing.mutateAsync({id:"",schema:companyID?.companyData?.company});
  }

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleSelectRole = (event) => {
    const newValue = event.target.value;
    setValue("role", newValue);
  };

  const handleSelectOffice = (event) => {
    const newValue = event.target.value;
    setValue("office", newValue);
    const filteredOffice = office.filter(item => item.id === parseInt(newValue));
    if (filteredOffice[0]) {
      setLocation(filteredOffice[0].city);
    }
  };

  const handleSelectDoc = (event) => {
    const newValue = event.target.value;
    setValue("docId", newValue);   
  };

  const roleListing = authQueries.useUserRoleListMutation(
    (response) => {
      setRole(response.data);
    }
  );
  const officeListing = authQueries.useOfficeListMutation(
    (response) => {
      setOffice(response.data?.items);
    }
  );

  const docListing = authQueries.useUserDocListMutation(
    (response) => {
      setdoc(response?.data);
    }
  );

  const fulldocListing = authQueries.useFullAccountUserListingDocMutation(
    (response) => {
      setdoc(response?.data);
    }
  );

  const userchecking = authQueries.useUserExistMutation(
    (response) => {
    }
  );
  


  const setInitialValue = (data) => {
    InitCall(data);
    setEditValue(data);
    let datas = {
      id: data.id,
      username:data.username,
      pass:data.pass,
      fname: data.fname,
      lname: data.lname,
      email:data.email,
      phone:data.phone,
      docId:data.user_doc_relation?.doc_id,
      office:data.user_office?.officeId,
      role:data.user_role?.roleid,
      status: selectedValue,
    };
    setSelectedValue(data.status)
    reset({ ...datas });
    const filteredOffice = office.filter(item => item.id === parseInt(data.user_office?.officeId));
    setLocation(filteredOffice && filteredOffice.length > 0 ? filteredOffice[0].city : null);
    onOpen(true);
  };

  const AddData = authQueries.useUserAddMutation((response) => {
    if (response?.message === "Success") {
      navigate(RouteConstants.USER);
    } else if (response?.error[0]?.name) {
      setErrName("⚠ " + response?.error[0]?.name);
    }
  });

  const updateData = authQueries.useUserUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteData = authQueries.useUserMutationDelete((response) => {
    mutateAsync({search: companyID?.companyFilter?.selectedUserkey,  role:companyID?.companyFilter?.selectedRole, status: "ACTIVE", page: 0, size: 10, schema:companyID?.companyData?.company });
    messages.success("successfully deleted");
  });

  const onSubmit = async (data) => {
    const username=data.username;
    try {
      const result = await userchecking.mutateAsync({ username, userid: editValue?.id || '', });
  
      if (result.exists) {
        setUserexistErr(result.exists);
        return; // Exit the function early
      }else{
        setUserexistErr(result.exists)
      }
    } catch (error) {
      console.error("Error checking username existence:", error);
      return; // Exit the function early in case of an error
    }
    setIsbuttonLoading(true)
    const datavalues = {
      username:data.username,
      pass:data.pass,
      fname: data.fname,
      lname: data.lname,
      email:data.email,
      phone:data.phone,
      office:data.office,
      docId:data.docId,
      role:parseInt(data.role),
      status: selectedValue,
      schema:companyID?.companyData?.company
    };
    if (!editValue) {
      AddData.mutateAsync(datavalues).then(() => {
        setIsbuttonLoading(false)
        onOpenChange(false);
        mutateAsync({ search: companyID?.companyFilter?.selectedUserkey, status: companyID?.companyFilter?.selectedAccntStatus, role:companyID?.companyFilter?.selectedRole, page: 0, size: 10 , schema:companyID?.companyData?.company});
        let datas = {
          username:"",
          pass:"",
          fname: "",
          lname: "",
          email:"",
          phone:"",
          office:"",
          docId:"",
          role:"",
          status: selectedValue,
        };
        setSelectedValue("ACTIVE")
        reset({ ...datas });
      });
    } else {
      const datavalues = {
        id: editValue.id,
        username:data.username,
        pass:data.pass,
        fname: data.fname,
        lname: data.lname,
        email:data.email,
        phone:data.phone,
        office:data.office,
        docId:data.docId,
        role:data.role,
        status: selectedValue,
        schema:companyID?.companyData?.company
      };
      updateData.mutateAsync(datavalues).then(() => {
        setIsbuttonLoading(false)
        let datas = {
          username:"",
          pass:"",
          fname: "",
          lname: "",
          email:"",
          phone:"",
          office:"",
          docId:"",
          role:"",
          status: "ACTIVE",
        };
        reset({ ...datas });
        mutateAsync({ search: companyID?.companyFilter?.selectedUserkey, status: companyID?.companyFilter?.selectedAccntStatus, role:companyID?.companyFilter?.selectedRole, page: 0, size: 10, schema:companyID?.companyData?.company });
      });
    }
  };

  const deleteEvent = (event, id) => {
    event.preventDefault();
    deleteData.mutateAsync({id,schema:companyID?.companyData?.company});
  };

  const addButton = ()=>{
    setEditValue(null);
    let datas = {
      username:"",
      pass:"",
      fname: "",
      lname: "",
      email:"",
      phone:"",
      office:"",
      docId:"",
      role:"",
      status: "ACTIVE",
    };
    reset({ ...datas });
    onOpen(true);
    InitCall('');
    onOpen(true);
  }


  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
        <div class="row">
                <div class="col-md-6">
                    <h3 class="dashboard-section-title">Users </h3>
                </div>
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                        
                    </div>
                </div>
            </div>
          <div className="text-end p-3">
            <Button
              color="primary"
              className="btn btn-add"
              onPress={addButton}
            >
              <i className="icon-add"></i> Add
            </Button>
          </div>
          <div className="table-wrpr">
            <div className="table-section">
              <table className="table">
                <thead>
                  <tr>
                    <th width="20">#</th>
                    <th width="100">Username</th>
                    <th width="100">Firstname</th>
                    <th width="250">Last Name</th>
                    <th width="250">Email</th>
                    <th width="250">Phone</th>
                    <th width="250">Role</th>
                    <th width="250">Status</th>
                    <th width="50">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.items?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1) + result?.currentPage * 10}</td>
                      <td>{item.username}</td>
                      <td>{item.fname}</td>
                      <td>{item.lname}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.user_role.role.role}</td>
                      <td>
                      <span className={`status ${item.status === 'ACTIVE' ? 'open' : 'inactive'}`}>{item.status}</span>
                      </td>
                      <td className="action-links">
                        <a
                          href="#"
                          onClick={() => setInitialValue(item)}
                          className="btn btn-view"
                        >
                          Edit
                        </a>
                      {companyID?.user?.role==='SHIPCONREPADMIN' || 'SUPERADMIN'?
                        <Popconfirm
                          placement="left"
                          title="Are you sure to delete this ?"
                          onConfirm={(event) => {
                            deleteEvent(event, item.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className="btn btn-view">Delete</button>
                        </Popconfirm>:null
                      }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination-wrpr">
            <Pagination  
            showControls
            total={result?.totalPages} 
            onChange={(e) => {mutateAsync({search: companyID?.companyFilter?.selectedUserkey,  role:companyID?.companyFilter?.selectedRole,  status: companyID?.companyFilter?.selectedAccntStatus, page: e-1 , size:pageSize, schema:companyID?.companyData?.company},setCurrentPage(e-1))}}
            initialPage={1}  
            />
          </div>
        </div>
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        register={register}
        errors={errors}
        errName={errName}
        role={role}
        office={office}
        handleSelectRole={handleSelectRole}
        handleSelectOffice={handleSelectOffice}
        location={location}
        doc={doc}
        handleSelectDoc={handleSelectDoc}
        userexistErr={userexistErr}
        isLoading={isbuttonLoading}
        code={code}
        setCode={setCode}
        control={control}
        Controller={Controller}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />
    </div>
  );
};
export default UserList;
