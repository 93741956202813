import React,{useState} from 'react';
import { DatePicker, Space } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const DateTimePicker = ({ showTime, format, onChange, onOk, register ,value}) => {

const { RangePicker } = DatePicker;


const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};


  return (
    <Space direction="vertical" size={12}>
      <DatePicker  disabledDate={disabledDate} {...register("datetime")} value={value}   showTime={showTime} onChange={onChange} onOk={onOk} />
    </Space>
  );
};

export default DateTimePicker;
