import React, { useEffect, useState } from "react";
import Issueview from './issueView';
import Action from './action';
import authQueries from "../../queries/authQueries";
import { useParams } from 'react-router-dom';
import { userStore } from '../../store/userStore';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from "@nextui-org/react";
import CheckboxList from '../common/checkbox';
import { useNavigate } from "react-router-dom";
import PublicIssueview from "./PublicIssueView";
import RouteConstants from "../../Constants/RouteConstant";

export default function PublicComplaintView () {
  const [result, setResult] = useState([]);
  const [category, setCategory] = useState([]);
  const companyID = userStore();
  const { id } = useParams();
  const [errName, setErrName] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [isAssigningCategory, setIsAssigningCategory] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [actioncategory, setActioncategory]=useState("");
  const [users, setUsers] = useState([])
  const [edit, setEdit] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [acceptText, setAcceptText] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    initCall();
  }, []);

  const initCall = async () => {
    await mutateAsync(id);
    await categoryListing.mutateAsync({ search: "", status: "", page: 0, size: 1000, schema: companyID?.companyData?.company });
  }

  const handleOpen = () => {
    onOpen();
  }
  
  const { mutateAsync } = authQueries.useComplaintePublicViewMutation(
    (response) => {
      console.log(response)
    setResult(response?.data);
  });

  const categoryListing = authQueries.useCategoryListMutation((response) => {
    setCategory(response?.data);
  });

  const categoryIds = result?.categories?.reduce((accumulator, item) => {
    if(item?.complaint_category?.status!=='INACTIVE'){
      const id = parseInt(item.id);
      accumulator.push(id);
    }
    return accumulator;
  }, []);

  const categoryIdsDisabled = result?.categories?.reduce((accumulator, item) => {
    const id = parseInt(item.id);
    if (item?.complaint_category?.created_by === null) {
      accumulator.push(id);
    }
    return accumulator;
  }, []);

  const assignCategoryApi = async () => {
    setIsAssigningCategory(true);
    const datavalues = {
      compliantID: result.id,
      category: checkboxes,
      schema: companyID?.companyData?.company
    };

    try {
      await assignCategory.mutateAsync(datavalues);
      onClose();
    } catch (error) {
      // Handle the error here, e.g., show an error message to the user.
      console.error('Error in assignCategoryApi:', error);
    } finally {
      setIsAssigningCategory(false);
    }
  };

  const assignCategory = authQueries.useAssignCategoryMutation((response) => {
    if (response?.message === "Success") {
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const handleAssignUser = authQueries.useComplaintReassignMutation(
    (res)=> {
      mutateAsync({ id: id, schema: companyID?.companyData?.company }).then(res => {
        setEdit(false)
        setBtnLoader(false)
      })
    }
  )

  const handleAssign =(data)=> {
    handleAssignUser.mutateAsync({data:data,schema: companyID?.companyData?.company})
  }

  const handleAcceptComplaint = authQueries.useComplaintAcceptMutation(
    (res)=>{
      mutateAsync({ id: id, schema: companyID?.companyData?.company})
    }
  )

  const handleAccept =()=> {
    handleAcceptComplaint.mutateAsync({issue: acceptText, complaint_id:result?.id, assignee: companyID?.user?.id})
  }

  return (
    <>
      <ul className="breadcrumb">
        <li><a onClick={()=>{navigate(RouteConstants.DASHBOARD) } } href="#"><i className="icon-home4"></i> Home</a></li>
        <li><a href="#"> Issue #{result?.reference_num}</a></li>
      </ul>

      <div className="row issue-details-wrpr" >
        <PublicIssueview
          compliant={result}
          categoryIdsDisabled={categoryIdsDisabled}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          assignCategoryApi={assignCategoryApi}
          handleOpen={handleOpen}
          actioncategory={actioncategory}
          setActioncategory={setActioncategory}
          setAcceptText={setAcceptText}
          acceptText={acceptText}
          handleAccept={handleAccept}
        />
      </div>

      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        backdrop='blur'
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Assign New Category</ModalHeader>
              <ModalBody>
                <div className='custom-check'>
                  <CheckboxList
                    data={category.items}
                    selectedIds={categoryIds}
                    categoryIdsDisabled={categoryIdsDisabled}
                    checkboxes={checkboxes}
                    setCheckboxes={setCheckboxes}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onPress={assignCategoryApi}>
                  Assign
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
