import React, { useEffect, useState } from "react";
import authQueries from "../../queries/authQueries";
import LoaderScreen from "../../components/common/LoaderScreen";
import { userStore } from '../../store/userStore';

const SurveyReport = () => {

    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(true)
    const companyID = userStore();
    const [fromDate, setfromDate] = useState('2023-04-01');
    const [toDate, settoDate] = useState('2024-03-31');

    useEffect(() => {
        mutateAsync({fromdate:fromDate, todate:toDate}).then(() => {  
          setLoading(false);
        });
      }, []);

      const InitCall =async ()=> {
        console.log("companyID?.reportFilter",companyID)
        setLoading(true); 
        mutateAsync({fromdate:companyID?.reportFilter?.fromDate, todate:companyID?.reportFilter?.toDate,rank:companyID?.companyFilter?.selectedRank,ship:companyID?.companyFilter?.selectedShiptype,age:companyID?.companyFilter?.selectedAge,gender:companyID?.companyFilter?.selectedGender })
        .then(() => {
          setLoading(false); 
        })
        .catch(() => {
          setLoading(false); 
        });
    
    }
    
    useEffect(() => {
      InitCall();
    }, [companyID]);

      
    const { mutateAsync } = authQueries.useSurveyReportMutation((response) => {
        setResult(response);
      });

      

      return (
        <div className="container">
          <div className="row">
            {
              loading ?
              <LoaderScreen />:
              <div className="col-md-12">
                  <div class="row">
    
                    <div class="col-md-6">
                        <div class="text-end p-3 pt-0">
                            
                        </div>
                    </div>
                </div>
                <div className="">
                <p>Total Number of Users who completed Survey : {result?.userCOunt[0]?.count}</p>
                </div>
                <div className="table-wrpr">
                  <div className="table-section">
                    <table className="table">
                    <thead>
                        <tr>
                        <th style={{ textAlign: "center" }} colSpan={5}>Number Of Users Selected</th>
                          
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th width="150">Statement</th>
                          <th width="150">All of the Time</th>
                          <th width="150">Most Of the Time</th>
                          <th width="150">Sometimes</th>
                          <th width="150">Never</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.data?.map((item, index) => (
                          <tr key={index}>
                            <td><b>{
                            item.survey_id===1?'I can freely report all unsafe acts/conditions':
                            item.survey_id===2?'There is teamwork among my co workers':
                            item.survey_id===3?'Management cares about my safety and wellbeing':
                            item.survey_id===4?'Existence of no blame culture':
                            item.survey_id===5?'High priority on safety training':
                            item.survey_id===6?'Adequate rest provided':
                            item.survey_id===7?'Breaking rules to achieve target':
                            item.survey_id===8?'Regular safety checks':
                            item.survey_id===9?'I know what is expected of me at work':
                            item.survey_id===10?'I feel the freedom to “think outside the box.”':
                            item.survey_id===11?'I am fairly compensated':
                            item.survey_id===12?'I have the equipment/materials/resources I need in order to perform my job to the best of my ability.':
                            item.survey_id===13?'My assignments are clearly explained.':
                            item.survey_id===14?'I feel rushed to ensure that I meet deadlines.':
                            item.survey_id===15?'I feel like I do not get enough to do.':
                            item.survey_id===16?'I have opportunities to learn things outside of my specific job.':
                            item.survey_id===17?'I have the energy at the end of the work day to participate in my personal hobbies and interests.':
                            item.survey_id===18?'I feel that I can talk to my supervisor/manager about matters which concern me.':
                            item.survey_id===19?'My supervisor/manager stays up-to-date on what I am working on and what I have completed.':
                            item.survey_id===20?'I am complimented and given constructive criticism.':
                            item.survey_id===21?'I feel that my supervisor/manager doesn’t understand exactly what I do on a daily basis.':null

                            }</b></td>
                            <td><b>{item?.allcount}</b></td>
                            <td><b>{item?.mostcount}</b></td>
                            <td><b>{item?.sometimescount}</b></td>
                            <td><b>{item?.nevercount}</b></td> 
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      );

}

export default SurveyReport;