import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import CustomSelect from "../../common/customSelect";

export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
  handleSelectCountry,
  country
}) {
  const [data, setData] = useState(editValue);
  //useEffect(() => { setVal(editValue);  }, [editValue] )
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Modal  size="3xl"
    backdrop="blur"  isOpen={isOpen} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {editValue ? "Update Office" : " Add Office"}
              </ModalHeader>
              <ModalBody>
              <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3 mt-2">
                    <Input
                        {...register("name")}
                        id="name"
                        type="text"
                        label="Office"
                        labelPlacement="outside"
                        placeholder="Enter your Office Name"
                        name="name"
                      />
                      <div className="error-msg">
                        {errors.name?.message} {errName}
                      </div>
                     
                    </div>

                    <div className="mb-3 mt-2">

                    <Select
                      label="Select Country" 
                      labelPlacement="outside"
                      defaultSelectedKeys={editValue?.country?.id ? [editValue.country?.id] : []}
                     // defaultSelectedKeys={[editValue?.country.id || "4"]}
                        placeholder="Select Country"
                        {...register("country_id", {
                        })}
                        onChange={(e) => handleSelectCountry(e)}
                        name="country_id"
                        >
                        {country.map((option) => (
                          <SelectItem key={option.id} value={option.id}>
                            {option.country}
                          </SelectItem>
                        ))}
                    </Select>
                    <div className="error-msg">
                        {errors.country_id?.message} {errName}
                      </div>
                     
                    </div>
                    <div className="mb-3 mt-2">
                      <Input
                        {...register("zipcode")}
                        id="zipcode"
                        type="text"
                        label="zipcode"
                        labelPlacement="outside"
                        placeholder="Enter your Zipcode"
                        name="zipcode"
                      />
                    </div>

                    <div className="mb-3 mt-2">
                      <CustomSelect
                        name="status"
                        label="Select Status"
                        register={register}
                        handleSelectChange={handleSelectChange}
                        defaultSelectedKeys={[editValue?.status || "ACTIVE"]}
                        options="statusOptions"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3 mt-2">
                    <Input
                        {...register("city")}
                        id="city"
                        type="text"
                        label="City"
                        labelPlacement="outside"
                        placeholder="Enter your City"
                        name="city"
                      />
                    </div>

                    <div className="mb-3 mt-2">
                      <Input
                        {...register("telephone")}
                        id="telephone"
                        type="text"
                        label="Telephone"
                        labelPlacement="outside"
                        placeholder="Enter your Telephone"
                        name="telephone"
                      />
                    </div>

                    <div className="mb-3 mt-2">
                   
                    </div>

                    <div className="mb-3 mt-2">
                    <Textarea
                        {...register("address")}
                        variant="faded"
                        label="Address"
                        labelPlacement="outside"
                        placeholder="Enter your address"
                        name="address"
                      />
                    </div>

                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
