import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import 'aos/dist/aos.css'; 
import { useNavigate } from "react-router-dom";
import RouteConstants from "../Constants/RouteConstant";
import Contact from "./contactForm";



const PrivacyPolicy =  () =>{
    const [previousScroll, setPreviousScroll] = useState(0);
    const [isModalopen, setisModalopen]=useState(false);
    const navigate = useNavigate();
    const handleScroll = () => {

        const currentScroll = window.scrollY;

        if (currentScroll > 480) {
            NavTrans();
        } else {
            removeNavTrans();
        }

        if (currentScroll < 80) {
            showTopNav();
        } else if (currentScroll > 0 && currentScroll < document.body.scrollHeight - window.innerHeight) {
            if (currentScroll > previousScroll) {
                hideNav();
            } else {
                showNav();
            }
            setPreviousScroll(currentScroll);
        }
    };


	const hideNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-visible');
            navbar.classList.add('is-hidden');
        }
    };

    const showNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden');
            navbar.classList.add('is-visible', 'scrolling');
        }
    };

    const showTopNav = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('is-hidden', 'scrolling');
            navbar.classList.add('is-visible');
        }
    };

    const NavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.add('whiteNav');
        }
    };

    const removeNavTrans = () => {
        const navbar = document.getElementById('navbar');
        if (navbar) {
            navbar.classList.remove('whiteNav');
        }
    };


	useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [previousScroll]);


    useEffect(() => {
        AOS.init();
      }, []);


    return (
        <>
        <nav id="navbar" class="navbar navbar-expand-md ml-3 mr-3  navbar-light  is-visible  fixed-top ">
            <div class="navbar-brand   order-1 order-md-0 dual-collapse2">
                <a class="navbar-brand mr-auto" onClick={()=>{navigate(RouteConstants.ROOT)}}>
                    
                <img class="logo"  src={require("../assets/images/site/logo.png")} />
                </a>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar">
                    <span class="navbar-toggler-icon">
                    <span></span>
                    </span>
                </button>
           </div>
            <div class="navbar-collapse  mx-auto order-0 header-center-nav" id="collapsingNavbar">
                    <ul class="navbar-nav me-auto">
                    </ul>
                    <ul class="navbar-nav ms-auto">
                                    
                        <li class="nav-item">
                            <a  class="nav-link employer-login" href={process.env.REACT_APP_FRONT_BASE_URL+'/login'}>Employer Login</a>
                        </li>          
                   </ul>
            </div>
</nav>


<header class="masthead privacy-policy">
	<div class="overlay"></div>
  <div class="container h-100">
    <div class="row h-100 text-center">
      <div class="col-12 bnr-top-pding ">
			<h1 data-aos="fade-down" >Privacy Policy</h1>
			<p>Shipping Industry Confidential Reporting Ltd Privacy Policy</p>
			
      </div>
	  
    </div>
  </div>
  
</header>



<div class="container privacy-wrpr" data-aos="fade-down" data-aos-delay="500">
		
		<div class="row">
			<div class="col-md-12">
				
				<h5>Our contact details</h5>
				<p><strong>Name: </strong> &nbsp;Shipping Industry Confidential Reporting Ltd</p>
				<p><strong>Address: </strong> &nbsp;The Old Rectory, Church Street, Weybridge, Surrey, England, KT13 8DE, United Kingdom</p>
				<p><strong>E-mail: </strong> &nbsp;info@shipconrep.com</p>
				<p><strong>Date: </strong> &nbsp;1st September 2022</p>
				
				<h5 class="mt-5">The type of personal information we collect</h5>
				<p>We currently collect and process the following information:</p>
				<ul>
					<li>Your name, e-mail address and phone numbers as provided to interact with you directly.</li>
					<li>We collect information about your location when you use our services, which helps us verify the details you submitted in your concern. Your location can be determined with varying degrees of accuracy by:</li>
					<ul class="alpha">
						<li>GPS and other&nbsp;sensor data from your device</li> 
						<li>IP address</li>
					</ul>
					
					<li>We use the information you provide for analytics and measurement to understand how our services are used.</li>
				</ul>
				
				<h5 class="mt-5">How we get the personal information and why we have it</h5>
				<p>Most of the personal information we process is provided to us directly by you when you submit a concern through the website managed by us.</p>
				<p>We use the information that you have given us in order to investigate the concerns raised by you, if your employer enlists the ombudsman services provided by our parent company.</p>
				<p>We do not share your personal information with your employer or any other party without your written agreement.</p>
				
				<h5 class="mt-5">Your consent</h5>
				<p>You are able to remove your consent at any time. You can do this by contacting <a href="info@shipconrep.com">info@shipconrep.com</a></p>
				<ul class="alpha">
					<li>We have a legal obligation.</li>
					<li>We have a vital interest.</li>
					<li>We have a legitimate interest.</li>
					
				</ul>
				
				
				   <h5 class="mt-5">How we store your personal information</h5>
				   <p>Your information is securely stored.</p>
				   <p>We keep the e-mail address and phone number you provided for a period of twelve months. We will then dispose your information by permanently deleting that information from our database.</p>
				   
				   <h5 class="mt-5">Your data protection rights</h5>
				   <p>Under data protection law, you have rights including:</p>
				   <p><strong>Your right of access - </strong>You have the right to ask us for copies of your personal information.</p>
				   <p><strong>Your right to rectification - </strong>You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</p>
				   <p><strong>Your right to erasure - </strong>You have the right to ask us to erase your personal information in certain circumstances.</p>
				   <p><strong>Your right to restriction of processing - </strong>You have the right to ask us to restrict the processing of your personal information in certain circumstances.</p>
				   <p><strong>Your right to object to processing - </strong>You have the the right to object to the processing of your personal information in certain circumstances.</p>
				   <p><strong>Your right to data portability - </strong>You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</p>
				   <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>
				   <p>Please contact us at <a href="info@shipconrep.com">info@shipconrep.com</a> if you wish to make a request.</p>
				   
				   <h5 class="mt-5">Links to Other Websites</h5>
				   <p>Our App may contain links to other websites of interest. However, once you have used these links, you should note that we do not have any control over the other website. Therefore, we cannot be responsible for the protection and privacy of any information that you provide while visiting such sites and such sites are not governed by this Privacy Notice. You should exercise caution and look at the privacy notice applicable to the website in question.</p>
				   
				   <h5 class="mt-5">How to complain</h5>
				   <p>If you have any concerns about our use of your personal information, you can make a complaint to us at  <a href="info@shipconrep.com">info@shipconrep.com</a></p>
				   
				   
				
				
			</div>
		</div>
	
		
</div>

<footer className="footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-2">
                                {/* <img src={require("../assets/images/site/Ship-icon-rep-logo.png")} /> */}
                            </div>
                            <div class="col-md-10 bottom-border">
                                <div class="row">
                                    <div class="col-md-5">
                                        <h5>Shipconrep</h5>
                                        <div class="anchors">
                                        <a  href={RouteConstants.ABOUT}>About Us</a>
                                        <a  href={RouteConstants.FAQ}>FAQs</a>
                                        <a  href={RouteConstants.PRIVACYPOLICY} >Privacy Policy</a>
                                        <a className="contactUs" onClick={ ()=>{setisModalopen(true)}}>Contact Us</a>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <h5>Services</h5>
                                        <div class="anchors">
                                        <a href="/frontend">Web Hotline</a>
							            <a href="/login">Employer Portal</a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <h5>Email ID</h5>
                                        <div class="anchors">
                                            <a href="#">info@shipconrep.com</a>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <hr class="border-white" />
                                    </div>
                                    
                                    <div class="col-md-12 text-center pt-3 pb-5">
                                    <h4>This portal is administered by:</h4>
                                        <p>Shipping Industry Confidential Reporting Limited. Registered in England and Wales: 13395969</p>
                                    </div>
                                    <div class="col-md-12">
					<div class="anchors" style={{display:'flex'}}>
					  <a  href="https://www.facebook.com/people/Shipping-Industry-Confidential-Reporting/100094405543592/"><img className="fbicon" src={require("../assets/images/site/fb.jpg")} /></a>
					  <a  href="https://www.linkedin.com/company/shipping-industry-confidential-reporting-pvt-ltd"><img className="fbicon" src={require("../assets/images/site/linkedin.png")} /></a>
					  </div>
					</div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </footer>





                <Contact isModalopen={isModalopen} setisModalopen={setisModalopen} />


        </>
    )


}

export default PrivacyPolicy