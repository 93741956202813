import React from 'react';// Import Next UI components
import {
    Select,
    SelectItem,
  } from "@nextui-org/react";

function CustomSelect({ label, name, register, handleSelectBoolean,handleSelectChange,defaultSelectedKeys, options }) {
    const statusOptions = [
        { value: 'ACTIVE', label: 'ACTIVE' },
        { value: 'INACTIVE', label: 'INACTIVE' },
      ];
      const BooleanOptions = [
        { value: 'NO', label: 'NO' },
        { value: 'YES', label: 'YES' },
      ];
      let handleFunction=handleSelectChange;

      if(options==='statusOptions'){
        handleFunction=handleSelectChange;
        options=statusOptions;
      }else if(options==='BooleanOptions'){
        handleFunction=handleSelectBoolean;
        options=BooleanOptions;
      }
  return (
    <Select
    label={label}
    labelPlacement="outside" 
      placeholder={`Select ${name}`}
      defaultSelectedKeys={defaultSelectedKeys}
      {...register(name, {
        onChange: (e) => {
          handleFunction(e);
        },
      })}
      name={name}
      disallowEmptySelection
    >
      {options.map((option) => (
        <SelectItem key={option.value} value={option.value}>
          {option.label}
        </SelectItem>
      ))}
    </Select>
  );
}

export default CustomSelect;
