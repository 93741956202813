import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import CustomSelect from "../../common/customSelect";
import { useEffect, useState } from "react";
import { userStore } from "../../../store/userStore";
import {Spinner} from "@nextui-org/react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'


export default function FormModal({
  editValue,
  isOpen,
  onOpenChange,
  handleSubmit,
  onSubmit,
  selectedValue,
  register,
  errors,
  errName,
  handleSelectChange,
  role,
  office,
  handleSelectRole,
  handleSelectOffice,
  location,
  doc,
  handleSelectDoc,
  userexistErr,
  isLoading,
  code,
  setCode,
  control,
  Controller,
  selectedRole,
  setSelectedRole
}) {

  const store = userStore()

 
  return (
    <Modal size="3xl" backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
              {editValue ? "Update User" : "Add New User"}
              </ModalHeader>
              <ModalBody>
                <div className="row">
                    <div className="col-md-6">
                    <div class="mb-3 mt-2">
                      <Input
                        {...register("username")}
                        id="name"
                        type="text"
                        label="Username"
                        labelPlacement="outside"
                        placeholder="Enter the Username"
                      />
                      <div className="error-msg">
                        {userexistErr?"⚠ Username Already Exist":null}
                        {errors.username?.message} {errName}
                      </div>
                    </div>
                    </div>
                    {!editValue&&
                      <div className="col-md-6">
                        <div class="mb-3 mt-2">
                          <Input
                            {...register("pass")}
                            id="pass"
                            type="text"
                            label={<p>Password <span style={{fontSize:'12px', color:'red'}}>*User to change password on first time login</span></p>}
                            labelPlacement="outside"
                            placeholder="Enter Password"
                          />
                          <div className="error-msg">
                            {errors.pass?.message} {errName}
                          </div>
                        </div>
                      </div>
                    }
                    <div className="col-md-6">
                      <div class="mb-3 mt-2">
                        <Input
                          {...register("fname")}
                          id="fname"
                          type="text"
                          label="Firstname"
                          labelPlacement="outside"
                          placeholder="Enter the Firstname"
                        />
                        <div className="error-msg">
                          {errors.fname?.message} {errName}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3 mt-2">
                        <Input
                          {...register("lname")}
                          id="lname"
                          type="text"
                          label="Lastname"
                          labelPlacement="outside"
                          placeholder="Enter the Lastname"
                        />
                        <div className="error-msg">
                          {errors.lname?.message} {errName}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3 mt-2">
                        <Input
                          {...register("email")}
                          id="email"
                          type="text"
                          label="Email"
                          labelPlacement="outside"
                          placeholder="Enter the Email"
                        />
                        <div className="error-msg">
                          {errors.email?.message} {errName}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="mb-3 mt-2">
                      <label style={{fontSize:'14px'}}>Phone Number</label>
                      <Controller
                        name='phone'
                        control={control}
                        className="form-control"
                        render={({ field: { onChange } }) => (
                          <PhoneInput
                              value={editValue?.phone}
                              enableSearch={true}
                              country={code}
                              onChange={(value, country, e, formattedValue) => {
                                setCode(country.countryCode)
                                onChange(value)
                              }}
                          /> 
                        )}
                        rules={{ required: true }}
                      />
                        {/* <Input
                          {...register("phone")}
                          id="phone"
                          type="text"
                          label="Phone"
                          labelPlacement="outside"
                          placeholder="Enter the Phone"
                        /> */}
                        <div className="error-msg">
                          {errors.phone?.message} {errName}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 mt-2">
                        <Select
                          label="Select Role" 
                          labelPlacement="outside"
                          defaultSelectedKeys={editValue?.user_role?.roleid ? [editValue.user_role?.roleid.toString()] : []}
                            placeholder="Select Role"
                            {...register('role', {
                              onChange: (e) => {
                                handleSelectRole(e)
                                setSelectedRole(e.target.value)
                              },
                            })}
                            name="role"
                            >
                             {role.map((option) => (
                                store?.user?.role === "SUPERUSER" ? 
                                  option.id === 4 &&
                                  <SelectItem key={option.id} value={option.id}>
                                    {option.role}
                                  </SelectItem>:
                                  store?.user?.role === "SHIPCONREPADMIN" ? 
                                  option.id !== 1 &&
                                  <SelectItem key={option.id} value={option.id}>
                                    {option.role}
                                  </SelectItem> :
                                  store?.user?.role === "SUPERADMIN" ? 
                                  (option.id !== 1 && option.id !== 2) &&
                                  <SelectItem key={option.id} value={option.id}>
                                    {option.role}
                                  </SelectItem> :
                                  <SelectItem key={option.id} value={option.id}>
                                  {option.role}
                                  </SelectItem>
                              ))}
                        </Select>
                        <div className="error-msg">
                            {errors.role?.message} {errName}
                          </div>
                      </div>
                    </div>
                    {(parseInt(selectedRole) === 3 || parseInt(selectedRole) === 4) &&
                      <div className="col-md-6" style={{display:'none'}}>
                        <div className="mb-3 mt-2">
                          <Select
                            label="Select Office" 
                            labelPlacement="outside"
                            defaultSelectedKeys={editValue?.user_office?.officeId ? [editValue.user_office?.officeId.toString()] : []}
                            //defaultSelectedKeys={editValue?.office.id ? [editValue.office.id] : []}
                              placeholder="Select Office"
                              {...register("office", {
                              })}
                              onChange={(e) => handleSelectOffice(e)}
                              name="office"
                              >
                              {office.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                  {option.name}
                                </SelectItem>
                              ))}
                          </Select>
                          <div className="error-msg">
                              {errors.office?.message} {errName}
                            </div>
                        </div>
                      </div>
                    }

                    {(parseInt(selectedRole) === 3 || parseInt(selectedRole) === 4) &&
                      <div className="col-md-6" style={{display:'none'}}>
                        <div class="mb-3 mt-2">
                            <Input
                              {...register("location")}
                              id="phone"
                              type="text"
                              label="Location"
                              labelPlacement="outside"
                              placeholder="Location"
                              disabled
                              value={location}
                            />
                        </div>
                      </div>
                    }


                    <div className="col-md-6">
                      <div class="mb-3 mt-2">
                        <CustomSelect label="Status"   labelPlacement="outside"  name="status" register={register} handleSelectChange ={handleSelectChange} defaultSelectedKeys= {editValue?.status? [editValue.status] : ["ACTIVE"]}  options="statusOptions" />
                      </div>
                    </div>

                    {(parseInt(selectedRole) === 3 || parseInt(selectedRole) === 4) &&
                      <div className="col-md-6">
                        <div className="mb-3 mt-2">
                          <Select
                            label="Select Doc Holder" 
                            labelPlacement="outside"
                            defaultSelectedKeys={editValue?.user_doc_relation?.doc_id ? [editValue.user_doc_relation?.doc_id] : []}
                              placeholder="Select Doc Holder"
                              {...register("docId", {
                              })}
                              onChange={(e) => handleSelectDoc(e)}
                              name="docId"
                              >
                              {doc?.map((option) => (
                                <SelectItem key={option?.id} value={option?.id}>
                                  {option?.name}
                                </SelectItem>
                              ))}
                          </Select>
                          <div className="error-msg">
                              {errors.docId?.message} {errName}
                          </div>
                        </div>
                      </div>
                    }
                 
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  {isLoading ?<Spinner className="testspinner" color="default" size="md" />:null}  
                  {editValue ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
