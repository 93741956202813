import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../Constants/RouteConstant";
import authQueries from "../../queries/authQueries";
import { Pagination } from '@nextui-org/react';
import LoaderScreen from "../common/LoaderScreen";
import { Button, useDisclosure } from "@nextui-org/react";

const DemoList = () =>{

    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        mutateAsync({ search: "", status: "", page: 0, size: 10 });
      }, []);

      const { mutateAsync } = authQueries.useDemoListMutation((response) => {
        setResult(response.data);
        setLoading(false)
      });


      return (
        <div className="container">
          <div className="row">
            {
              loading ?
              <LoaderScreen />:
              <div className="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="text-end p-3 pt-0">
                            
                        </div>
                    </div>
                </div>
                <div className="text-end p-3">
                </div>
                <div className="table-wrpr">
                  <div className="table-section demo-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="50">#</th>
                          <th width="50">Company</th>
                          <th width="150">Name</th>
                          <th width="200">Email</th>
                          <th width="50">Telephone</th>
                          <th width="50">Mobile</th>
                          <th width="50">Tailored Demo</th>
                          <th width="100">Doc Company</th>
                          <th width="100">Doc Company IMO</th>
                          <th width="100">Additional Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.items?.map((item, index) => (
                          <tr key={index}>
                            <td>{(index + 1) + result?.currentPage * 10}</td>
                            <td>{item.company_name}</td>
                            <td>{item.full_name}</td>
                            <td>{item.email}</td>
                            <td>{item.telephone}</td>
                            <td>{item.phone}</td>
                            <td>{item.require_demo}</td>
                            <td>{item.doccompany}</td>
                            <td>{item.doccompanyimo}</td>
                            <td data-bs-toggle="tooltip" title={item?.additional_message}>{item.additional_message.slice(0,50)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="pagination-wrpr">
                  <Pagination  
                  showControls
                  total={result?.totalPages} 
                  onChange={(e) => {mutateAsync({search: '', page: e-1 , size:pageSize},setCurrentPage(e-1))}}
                  initialPage={1}  
                  />
                </div>
              </div>
            }
          </div>
        </div>
      );

}

export default DemoList
