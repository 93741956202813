import React, { useEffect, useState } from "react";
import authQueries from "../../../queries/authQueries";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, useDisclosure } from "@nextui-org/react";
import "../../../assets/css/tailwind.css";
import FormModal from "./FormModal";
import { message as messages, Popconfirm } from "antd";
import { Pagination } from '@nextui-org/react';
import { userStore } from '../../../store/userStore';
import {Spinner} from "@nextui-org/react";
import RouteConstants from "../../../Constants/RouteConstant";

const validationSchema = yup.object({
  name: yup
    .string("⚠ Enter Doc Holder Name")
    .required("⚠  Doc Holder Name is required")
    .min(2, "⚠  Doc Holder Name must be more than 2 characters "),
  imo: yup
    .string("⚠ Enter Doc Holder IMO")
    .required("⚠ Doc Holder IMO is required")
    .min(2, "⚠ Doc Holder IMO must be more than 2 characters "),
  doc_code: yup
    .string("⚠ Enter Doc Holder Code")
    .required("⚠ Doc Holder Code is required")
    .min(4, "⚠ Doc Holder Code must be more than 4 characters "),
});

const DocHolderList = () => {
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("ACTIVE");
  const [errName, setErrName] = useState("");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editValue, setEditValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const companyID = userStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isbuttonLoading, setIsbuttonLoading] = useState(false);
  useEffect(() => {
    let FilterValues={
      selectedDoc:'',
      selectedVessel:'',
      selectedAccntStatus:'',
      selectedCategory:'',
      selectedPlatform:'',
      selectedReference:'',
      selectedVesselKey:'',
      selectedFleet:'',
      selectedDocKey:''
      
      
}
      companyID.setSelectedFilter(FilterValues);
    setIsLoading(true);
    mutateAsync({ search: "", status: "", page: 0, size: 10 , schema:companyID?.companyData?.company});
   
  }, []);

  useEffect(() => {

    
    setIsLoading(true);
    mutateAsync({ search: companyID?.companyFilter?.selectedDocKey, status: companyID?.companyFilter?.selectedAccntStatus, page: 0, size: 10 , schema:companyID?.companyData?.company});
   
  }, [companyID]);


  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = authQueries.useDocListMutation((response) => {
    setResult(response.data);
    setIsLoading(false);
  });

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const setInitialValue = (data) => {
    setEditValue(data);
    let datas = {
      id: data.id,
      name: data.name,
      imo:data.imo,
      address:data.address,
      telephone:data.telephone,
      doc_code:data.doc_code,
      status: selectedValue,
    };
    setSelectedValue(data.status)
    reset({ ...datas });
    onOpen(true);
  };

  const AddData = authQueries.useDocAddMutation((response) => {
    if (response?.message === "Success") {
      navigate(RouteConstants.DOC);
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const updateData = authQueries.useDocUpdateMutation((response) => {
    if (response?.message === "Success") {
      onOpenChange(false);
      mutateAsync({ search: "", status: "", page: 0, size: 10 , schema:companyID?.companyData?.company});
    } else if (response?.error[0].name) {
      setErrName("⚠ " + response?.error[0].name);
    }
  });

  const deleteData = authQueries.useDocMutationDelete((response) => {
    mutateAsync({ search: "", status: "ACTIVE", page: 0, size: 10 , schema:companyID?.companyData?.company});
    messages.success("successfully deleted");
  });

  const onSubmit = (data) => {
    setIsbuttonLoading(true);
    const datavalues = {
      name: data.name,
      imo:data.imo,
      address:data.address,
      telephone:data.telephone,
      doc_code:data.doc_code,
      status: selectedValue,
      schema:companyID?.companyData?.company
    };
    if (!editValue) {
      AddData.mutateAsync(datavalues).then(() => {
        setIsbuttonLoading(false);
        onOpenChange(false);
        mutateAsync({ search: "", status: "", page: 0, size: 10 , schema:companyID?.companyData?.company});
        let datas = {
          name: "",
          imo:"",
          address:"",
          telephone:"",
          doc_code:"",
          status: selectedValue,
        };
        setSelectedValue("ACTIVE")
        reset({ ...datas });
      });
    } else {
      const datavalues = {
        id: editValue.id,
        name: data.name,
        imo:data.imo,
        address:data.address,
        telephone:data.telephone,
        doc_code:data.doc_code,
        status: selectedValue,
        schema:companyID?.companyData?.company
      };
      updateData.mutateAsync(datavalues).then(() => {
        setIsbuttonLoading(false);
        let datas = {
          name: "",
          imo:"",
          address:"",
          telephone:"",
          doc_code:"",
          status: "ACTIVE",
        };
        reset({ ...datas });
      });
    }
  };

  const deleteEvent = (event, id) => {
    event.preventDefault();
    deleteData.mutateAsync({id:id,schema:companyID?.companyData?.company});
  };

  const addButton = ()=>{
    setEditValue(null);
    let datas = {
      name: "",
      imo:"",
      address:"",
      telephone:"",
      doc_code:"",
      status: "ACTIVE",
    };
    reset({ ...datas });
    onOpen(true);
  }

  return (
    <div className="container">
      <div className="row">

        <div className="col-md-12">
        <div class="row">
                <div class="col-md-6">
                    <h3 class="dashboard-section-title">Doc Holder </h3>
                </div>
                <div class="col-md-6">
                    <div class="text-end p-3 pt-0">
                        
                    </div>
                </div>
            </div>
          <div className="text-end p-3">
          {companyID?.user?.role==='SHIPCONREPADMIN'?
            <Button
              color="primary"
              className="btn btn-add"
              onPress={addButton}
            >
              <i className="icon-add"></i> Add
            </Button>:null
          }
          </div>
          {isLoading ? 
      (
      <div className="loader" >
            <Spinner size="large" />
      </div>
      ) : (
          <div className="table-wrpr">
            <div className="table-section">
              <table className="table">
                <thead>
                  <tr>
                    <th width="20">#</th>
                    <th width="100">Name</th>
                    <th width="100">IMO</th>
                    <th width="250">Doc Code</th>
                    <th width="250">Telephone</th>
                    <th width="250">Address</th>
                    <th width="250">Status</th>
                    <th width="50">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.items?.map((item, index) => (
                    <tr key={index}>
                      <td>{(index + 1) + result?.currentPage * 10}</td>
                      <td>{item.name}</td>
                      <td>{item.imo}</td>
                      <td>{item.doc_code}</td>
                      <td>{item.telephone}</td>
                      <td>{item.address}</td>
                      <td>
                        <span className={`status ${item.status === 'ACTIVE' ? 'open' : 'inactive'}`}>{item.status}</span>
                      </td>
                      <td className="action-links">
                        <a
                          href="#"
                          onClick={() => setInitialValue(item)}
                          className="btn btn-view"
                        >
                          Edit
                        </a>
                      {companyID?.user?.role==='SHIPCONREPADMIN'?
                        <Popconfirm
                          placement="left"
                          title="Are you sure to delete this ?"
                          onConfirm={(event) => {
                            deleteEvent(event, item.id);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className="btn btn-view">Delete</button>
                        </Popconfirm>:null
                      }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
            )}
          <div className="pagination-wrpr">
            <Pagination  
            showControls
            total={result?.totalPages} 
            onChange={(e) => {setIsLoading(true); mutateAsync({search: companyID?.companyFilter?.selectedDocKey, status: companyID?.companyFilter?.selectedAccntStatus, page: e-1 , size:pageSize, schema:companyID?.companyData?.company},setCurrentPage(e-1))}}
            initialPage={1}  
            />
          </div>
        </div>
         
      </div>
      <FormModal
        editValue={editValue}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        selectedValue={selectedValue}
        handleSelectChange={handleSelectChange}
        register={register}
        errors={errors}
        errName={errName}
        isLoading={isbuttonLoading}
        companyID={companyID}
        control={control}
      />
    </div>
  );
};
export default DocHolderList;
