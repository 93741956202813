import React,{useState, useEffect} from "react";
import { Chart } from "react-google-charts";
import authQueries from "../../../queries/authQueries";
import { userStore } from '../../../store/userStore';
import {Spinner} from "@nextui-org/react";


export default function NocodeReport() {

    const [shpType, setshpType] = useState([]);
    const [rank, setrank] = useState([]);
    const companyID = userStore();

    const initCall =  async()=>{
        await mutateAsync();
        await rankChart.mutateAsync();
      }

      const { mutateAsync } = authQueries.useShipType((response) => {
        setshpType(response.data);
      });

      const rankChart = authQueries.useRankchart(
        (response) => {
            setrank(response.data);
        }
      );

      // const shiptypeOptions = {
      //   chart: {
      //     title: "Ship Type Submission",
      //     subtitle: "Ship Type, issue submision count",
      //   },
      // };

      
      // const rankeOptions = {
      //   chart: {
      //     title: "Rank on Board Submission",
      //     subtitle: "Rank on Board, issue submision count",
      //   },
      // };


      useEffect(() => {
        initCall();
      }, []);


  return (

          <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                    <div class="chart-wrpr">
                          <div class="chart-header">
                            <h5>Issue  sorted by Shiptype</h5>
                          </div>

                            {shpType?.length>0 ? (
                              <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={shpType}
                               // options={shiptypeOptions}
                              />
                              ):<Spinner className="testspinner" color="Primary" size="lg" />}

                              <div id="columnchart_values"></div>
                                
                                <div class="chart-footer">
                                  <p>Ship Type</p>
                              </div> 

                           
                     </div>
                     <div class="chart-wrpr2">
                        
                        <div class="chart-header">
                          <h5>Issue  sorted by Rankonboard</h5>
                        </div>

                         {rank?.length>0 ? (
                              <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={rank}
                                //options={rankeOptions}
                              />
                              ):<Spinner className="testspinner" color="Primary" size="lg" />}
                              <div id="columnchart_values2"></div>
                              
                              <div class="chart-footer">
                                <p>Rank on Board</p>
                              </div>
                      </div>
                  </div>
                </div>
            </div>

    );


}