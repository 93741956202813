import React from 'react';// Import Next UI components
// import {
//     Select,
//     SelectItem,
//   } from "@nextui-org/react";

function FilterSelect({ label, name,handleSelectChange,defaultSelectedKeys, options, className }) {

      let handleFunction=handleSelectChange;


  return (
    <select
    style={{width:"100px"}}
    Myvalue="test"
    className={className}
    label={label}
      placeholder={`Select ${name}`}
      defaultSelectedKeys={defaultSelectedKeys}
      onChange={(e)=>( handleFunction(e))}
      name={name}
    >
      <option selected value="">{label}</option>
      {options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default FilterSelect;
