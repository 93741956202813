import React,{useState, useEffect} from 'react'
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { userStore } from '../../../store/userStore'
import authQueries from "../../../queries/authQueries";
import { useForm } from "react-hook-form";
import * as yup from "yup"
import FilterSelect from './filterSelect';
import RouteConstants from '../../../Constants/RouteConstant';


const Header = () => {
	const user = userStore();
	const store = userStore();
	
    const navigate = useNavigate();
	const [selectedStatus, setSelectedStatus] = useState('ACTIVE');
	const location = useLocation();
	const [docholder, setHocholder] = useState([]);
	const [docoptions, setdocoptions] = useState([]);
	const [selectedDoc, setSelectedDoc]= useState("");
	const [selectedVessel, setSelectedVessel]= useState("");
	const [selectedAccntStatus, setselectedAccntStatus]= useState("");
	const [selectedCategory, setselectedCategory]= useState("");
	const [selectedPlatform, setselectedPlatform]= useState("");
	const [selectedReference, setSelectedReference]= useState("");
	const [selectedVesselKey, setSelectedVesselKey]= useState("");
	const [selectedFleet, setSelectedFleet]= useState("");
	const [selectedDocKey, setSelectedDocKey]= useState("");
	const [selectedFleetkey, setSelectedFleetkey]= useState("");
	const [selectedUserkey, setSelectedUserkey]= useState("");

	const [selectedShiptype, setSelectedShiptype]= useState("");
	const [selectedRank, setSelectedRank]= useState("");
	const [selectedAge, setSelectedAge]= useState("");
	const [selectedGender, setSelectedGender]= useState("");


	const [selectedRole, setSelectedRole]= useState("");
	const [vesseloptions, setVesseloptions] = useState([]);
	const [fleetoptions, setFleetoptions] = useState([]);
	const [categoryoptions, setCategoryoptions] = useState([]);
	const [shiptypeoptions, setShiptypeoptions] = useState([]);
	const [rankooptions, setRankooptions] = useState([]);
	const [issueCount,setIssuecount]=useState({opencount:0,closedcount:0,acceptedcount:0});
	const [toggle, setToggle] = useState(false)
	const [fromDate, setfromDate] = useState(store?.reportFilter?.fromDate?store?.reportFilter?.fromDate:'');
	const [toDate, settoDate] = useState(store?.reportFilter?.fromDate?store?.reportFilter?.toDate:'');
	
    const InitCall =async ()=> {
        await docListing.mutateAsync({search: store?.companyFilter?.selectedDocKey, status: 'ACTIVE', page:0, size:1000, schema:user?.companyData?.company}).then((result)=>{
            const nameOptions = result?.data?.items.map((item) => ({
                value: item.id, 
                label: item.name,
              }));
			  setdocoptions(nameOptions)
          });

		  await vesselListing.mutateAsync({search: store?.companyFilter?.selectedVesselKey,fleet:"", doc:store?.companyFilter?.selectedDoc, status: 'ACTIVE', page:0, size:1000, schema:user?.companyData?.company}).then((result)=>{
            const nameOptions = result?.data?.items.map((item) => ({
                value: item.id, 
                label: item.name,
              }));
			  setVesseloptions(nameOptions)
          });

		  
		  await fleetListing.mutateAsync({search: store?.companyFilter?.selectedFleetkey,doc: store?.companyFilter?.selectedDoc,  status: 'ACTIVE', page:0, size:1000, schema:user?.companyData?.company}).then((result)=>{
            const nameOptions = result?.data?.items.map((item) => ({
                value: item.id, 
                label: item.name,
              }));
			  setFleetoptions(nameOptions)
          });

		  await categoryListing.mutateAsync({search: "", status: "", page:0, size:1000, schema:user?.companyData?.company}).then((result)=>{
            const nameOptions = result?.data?.items.map((item) => ({
                value: item.id,
                label: item.category,
              }));
			  setCategoryoptions(nameOptions)
          });

		  if(store?.user?.role !== "SUPERUSER"){
			  await countListing.mutateAsync({search: "", status: "", page:0, size:1000, schema:user?.companyData?.company})
		  }

		  if(store?.user?.role === "SUPERUSER"){
			await countAssignedListing.mutateAsync(countAssignedListing)
		  }

	if (location.pathname === RouteConstants.SURVEYREPORT){
		  await shiptypeListing.mutateAsync({ status: 'ACTIVE', page:0, size:1000}).then((result)=>{
            const nameOptions = result?.data?.items.map((item) => ({
                value: item.id, 
                label: item.type,
              }));
			  setShiptypeoptions(nameOptions)
          });

		  await rankonboardListing.mutateAsync({ status: 'ACTIVE', page:0, size:1000}).then((result)=>{
            const nameOptions = result?.data?.items.map((item) => ({
                value: item.id, 
                label: item.name,
              }));
			  setRankooptions(nameOptions)
          });
		}


      }
      const docListing = authQueries.useDocListMutation(
        (response) => {
          setHocholder(response?.data?.items);
        }
      );

	  const vesselListing = authQueries.useVesselListMutation(
        (response) => {

        }
      );

	  
	  const fleetListing = authQueries.useFleetListMutation(
        (response) => {

        }
      );

	  	  

	  const shiptypeListing = authQueries.useShipTypeListMutation(
        (response) => {

        }
      );

	  const rankonboardListing = authQueries.useRankonboardListMutation(
        (response) => {

        }
      );


	  const countListing = authQueries.useCompliantCountMutation(
        (response) => {
			setIssuecount(response?.counts)
        }
      );	  
	  
	  const countAssignedListing = authQueries.useCompliantAssignedCountMutation(
        (response) => {
			setIssuecount(response)
        }
      );

	  const categoryListing = authQueries.useCategoryListMutation(
        (response) => {

        }
      );


      useEffect(() => {
        InitCall();
      }, [store]);



	const logOut =()=>{
		user.setUser({})
		user.setCompanyData({})
		user.setCompany({})
		navigate("/login")
	}


	const handleSelectChange = (event, identifier) => {
		console.log("event", event.target.value)
		const newValue = event.target.value;
		let filterValues = {
		  selectedDoc,
		  selectedVessel,
		  selectedAccntStatus,
		  selectedCategory,
		  selectedPlatform,
		  selectedReference,
		  selectedVesselKey,
		  selectedFleet,
		  selectedDocKey,
		  selectedFleetkey,
		  selectedUserkey,
		  selectedRole,
          selectedShiptype,
          selectedRank,
          selectedAge,
          selectedGender,
		};
	
		switch (identifier) {
		  case 'doc':
			setSelectedDoc(newValue);
			filterValues.selectedDoc = newValue;
			break;
		  case 'vessel':
			setSelectedVessel(newValue);
			filterValues.selectedVessel = newValue;
			break;
		  case 'status':
			setselectedAccntStatus(newValue);
			filterValues.selectedAccntStatus = newValue;
			break;
		  case 'category':
			setselectedCategory(newValue);
			filterValues.selectedCategory = newValue;
			break;
		case 'platform':
			setselectedPlatform(newValue);
			filterValues.selectedPlatform = newValue;
			break;
		case 'reference':
			setSelectedReference(newValue);
			filterValues.selectedReference = newValue;
			break;
		case 'vesselkey':
			setSelectedVesselKey(newValue);
			filterValues.selectedVesselKey = newValue;
			break;
		case 'fleet':
			setSelectedFleet(newValue);
			filterValues.selectedFleet = newValue;
			break;
		case 'dockey':
			setSelectedDocKey(newValue);
			filterValues.selectedDocKey = newValue;
			break;
		case 'fleetkey':
			setSelectedFleetkey(newValue);
			filterValues.selectedFleetkey = newValue;
			break;
		case 'userkey':
			setSelectedUserkey(newValue);
			filterValues.selectedUserkey = newValue;
			break;
		case 'role':
			setSelectedRole(newValue);
			filterValues.selectedRole = newValue;
			break;
		case 'shiptype':
			setSelectedShiptype(newValue);
			filterValues.selectedShiptype = newValue;
			break;
		case 'rank':
			setSelectedRank(newValue);
			filterValues.selectedRank = newValue;
		   break;
		case 'age':
		    setSelectedAge(newValue);
			filterValues.selectedAge = newValue;
		    break;
		case 'gender':
			setSelectedGender(newValue);
			filterValues.selectedGender = newValue;
			break;
		  default:
			break;
		}
		store.setSelectedFilter(filterValues);

		console.log('filterValues: ',filterValues); 
	  };

	  const handleDatefilter = (event, identifier) => {
		const newValue = event.target.value;
		let filterValues = {
			fromDate,
			toDate,
		};
		if(identifier==='from'){
			setfromDate(newValue)
			filterValues.fromDate = newValue;
		}else{
			settoDate(newValue)
			filterValues.toDate = newValue;
		}
		store.setReportFilter(filterValues);
	  }

	  const handleReportfilterStatus = () => {
		store.setReportFilterOn(true);
	  }
	  


    const statusOptions = [
        { value: 'OPEN', label: 'OPEN' },
        { value: 'ACCEPTED', label: 'ACCEPTED' },
		{ value: 'CLOSED', label: 'CLOSED' },
	];

	const roleOptions = [
        { value: 2, label: 'SUPERADMIN' },
		{ value: 3, label: 'SUPERUSER' },
        { value: 4, label: 'Account User' }
		
	];

	const ageOptions = [
        {label: '18-25', value: 1},
		{label: '26-30', value: 2},
		{label: '31-35', value: 3},
		{label: '36-40', value: 4},
		{label: '41-45', value: 5},
		{label: '46-50', value: 6},
		{label: '51-55', value: 7},
		{label: '56-60', value: 8},
		{label: '60-Above', value: 9},
		
	];

	const genderOptions = [
        { value: 'MALE', label: 'MALE' },
		{ value: 'FEMALE', label: 'FEMALE' },
        { value: 'OTHER', label: 'OTHER' }
		
	];

	const active = [
        { value: 'ACTIVE', label: 'ACTIVE' },
        { value: 'INACTIVE', label: 'INACTIVE' },
	];

	const platformOptions = [
        { value: 'WEB', label: 'WEB' },
        { value: 'APP', label: 'MOB' },
	];

	const toggleOpen =()=> {
		setToggle(!toggle)
		document.body.classList.add('sidebar-active')
	}

	const toggleClose =()=> {
		setToggle(!toggle)
		document.body.classList.remove('sidebar-active')
	}
	

return (
<aside class="right-side">
	<div class="header">
		
		<div class="title-and-companylogo">
		
			<div className="page-title">
			<a onClick={!toggle ? toggleOpen : toggleClose} href="#" className="sidebar-toggler icon-menu1"></a>


				{(() => {
				const excludedPaths = [
					{ path:  RouteConstants.DASHBOARD, heading: 'Dashboard' },
					{ path: RouteConstants.PUBLICISSUES, heading: 'Issues with No Workplace Code' },
					{ path: RouteConstants.SHIPTYPELIST, heading: 'Ship Type' },
					{ path: RouteConstants.COMPANY, heading: 'Company' },
					{ path: RouteConstants.RANKLIST, heading: 'Rank On Board' },
					{ path: RouteConstants.CATEGORY, heading: 'Category' },
					{ path: RouteConstants.DEMO, heading: 'Requests for Demo' },
					{ path: RouteConstants.COUNTRY, heading: 'Country' },
					{ path: RouteConstants.REPORTDASH, heading: 'Reports' },
					{ path: RouteConstants.NOCODEREPORT, heading: 'No Code Statistics' },
					{ path: RouteConstants.EMPLOYERFAQ, heading: 'FAQ' },
					{ path: RouteConstants.SURVEYREPORT, heading: 'Survey Report' },
					{ path: RouteConstants.TOTALREPORT, heading: 'Total Category Report' },
					{ path: RouteConstants.RANKONBOARDNOCODE, heading: 'Rankonboard Report' },
					{ path: RouteConstants.CATEGORYNOCODE, heading: 'No workplace code category Report' },
					{ path: '/public_issueview', heading: '' },
					{ path: RouteConstants.PROFILE, heading: 'Profiles' },
				];
				const userRoles = ['SHIPCONREPADMIN', 'SUPERADMIN', 'SUPERUSER', 'ACCOUNTUSER'];
				const newPath = location.pathname.replace(/\/\d+$/, ''); 
				const currentPath = excludedPaths.find(item => item.path === newPath);

				if (currentPath && (userRoles.includes(user?.user?.role) || user?.company?.company?.name) ) {
				
					if(user?.user?.role==='SHIPCONREPADMIN'){
					return (
						<div class="company-logo-name-wrpr">
							<div class="head-company-name">{currentPath.heading}</div>
						</div>
					);
					}else{
						return (

							<div class="company-logo-name-wrpr">
								{user?.user?.logo ? 
								<div class="company-logo">
									<img src={`${process.env.REACT_APP_BASE_URL}/api/fileupload/${user?.user?.logo}`}  />
								</div>:null
								}
								<div class="head-company-name">{user?.company?.company?.name}</div>
							</div>
							);

					}

				}else{
					return (

						<div class="company-logo-name-wrpr">
					<a onClick={!toggle ? toggleOpen : toggleClose} href="#" className="sidebar-toggler icon-menu1"></a>
						{user?.user?.logo ? 
						<div class="company-logo">
							<img src={`${process.env.REACT_APP_BASE_URL}/api/fileupload/${user?.user?.logo}`}  />
						</div>:null
						}
						<div class="head-company-name">{user?.company?.company?.name}</div>
					</div>
					);
				}
				return null;
				})()}
			</div>
		
			<div class="user-profile-and-company-logo">
				<div class="user-profile-name-avatar">
					<a onClick={logOut} href="javascript:void(0)" data-link-alt="Logout">
						<span> 
						{
							user.user.role ==='SHIPCONREPADMIN'? 'Hi Shipconrep Admin': 
							user.user.role === "SUPERADMIN" ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Super Admin</span> : 
							user.user.role === "ACCOUNTUSER" ? <span>&nbsp;&nbsp;&nbsp;&nbsp;Account User</span> : 
							user.user.role === "SUPERUSER" && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Super User</span>
						}
						</span>
						<i class="icon-person"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
	{
    user.user.role !== 'SHIPCONREPADMIN' &&   user.user.role !== 'SUPERADMIN' && user.user.role !== 'ACCOUNTUSER' && user.user.role !== 'SUPERUSER' ? 
	<div class="sub-head">
		<div class="filter-search">
			<select 
			class="form-select form-select-sm rounded-5">
			<option selected disabled>Select Status</option>
			<option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
			</select>
		</div>
	</div>:null
	}

	{
		(user.user.role === 'SUPERADMIN'  && location.pathname === RouteConstants.DASHBOARD) || (user.user.role === 'SHIPCONREPADMIN'  && location.pathname === RouteConstants.ISSUES) || (user.user.role === 'ACCOUNTUSER'  && location.pathname === RouteConstants.DASHBOARD) || (user.user.role === 'SUPERUSER'  && location.pathname === RouteConstants.DASHBOARD) || (user.user.role === 'SHIPCONREPADMIN'  && location.pathname === RouteConstants.PUBLICISSUES)? 
		<div class="sub-head">
		<div class="filter-search">

			{/* <input type="search" 
				class="form-control form-control-sm rounded-5" 
				placeholder="Reference Number" 
				styles="min-width:135px" 
				 
			/> */}
			<div class="input-group custom-search">
				<input type="text" class="form-control" placeholder="Reference Number" onChange={(e) => handleSelectChange(e, 'reference')}  />
				<span class="input-group-text"><i class="icon-search1"></i></span>
			</div>
			{
			(user.user.role === 'SHIPCONREPADMIN')  && location.pathname !== RouteConstants.PUBLICISSUES && (
			<>
				<FilterSelect 
					name="status" label="Vessel"  handleSelectChange={(e) => handleSelectChange(e, 'vessel')} options={vesseloptions} 
					className="form-select form-select-sm rounded-5" 
				/>
				
			</>
			)
			}
		   {
			(user.user.role === 'SHIPCONREPADMIN' || user.user.role === 'SUPERADMIN')  && location.pathname !== RouteConstants.PUBLICISSUES && (
			<>
				<FilterSelect 
					name="status" label="Doc Holder" handleSelectChange={(e) => handleSelectChange(e, 'doc')}  options={docoptions} 
					className="form-select form-select-sm rounded-5" 
				/>				
			</>
			)
			}
				

			 	<FilterSelect 
					name="status" label="Status" handleSelectChange={(e) => handleSelectChange(e, 'status')} options={statusOptions} 
					className="form-select form-select-sm rounded-5" 
				/>
			  
				<FilterSelect 
					name="status" label="Issue Type" handleSelectChange={(e) => handleSelectChange(e, 'category')} options={categoryoptions} 
					className="form-select form-select-sm rounded-5" 
				/>

				{user.user.role === 'SHIPCONREPADMIN'?
				<FilterSelect 
					name="status" label="Platform"  handleSelectChange={(e) => handleSelectChange(e, 'platform')}     options={platformOptions} 
					className="form-select form-select-sm rounded-5" 
				/>:null
				}
			{/* <input type="text" class="form-control form-control-sm rounded-5" placeholder="Search Issue" styles="min-width:135px;" /> */}
		</div>
		{/* <div class="status-count">
			<div class="status open">
				<div>Open</div>
				<div class="count">{issueCount?.opencount}</div>
			</div>
			<div class="status closed">
				<div>Closed</div>
				<div class="count">{issueCount?.closedcount}</div>
			</div>
			<div class="status accepted">
				<div>Accepted</div>
				<div class="count">{issueCount?.acceptedcount}</div>
			</div>
			
		</div> */}
		</div>
	:null
	}

{
    user.user.role === 'SHIPCONREPADMIN' &&   (location.pathname === RouteConstants.REPORT || location.pathname === RouteConstants.CATEGORYREPORT || location.pathname === RouteConstants.VESSELCOMPANYREPORT || location.pathname === RouteConstants.CATEGORYNOCODE || location.pathname === RouteConstants.RANKONBOARDNOCODE || location.pathname === RouteConstants.TOTALREPORT) ? 
	<div class="sub-head justify-content-center">
		<div class="filter-search">
			<span class="dateinput-wrpr from"><input style={{minWidth: '100px'}} value={fromDate} onChange={(e)=>{handleDatefilter(e,'from')}}   className="form-control form-control-sm" type="date" placeholder="From" /></span>
			<span class="dateinput-wrpr to"><input style={{minWidth: '100px'}} value={toDate} onChange={(e)=>{handleDatefilter(e,'to')}}  className="form-control form-control-sm" type="date" placeholder="TO" /></span>
			<span style={{display: 'none'}} class="dateinput-wrpr blank"><button type="button" onClick={handleReportfilterStatus} class="btn btn-sm btn-datesearch btn-primary"><i class="icon-search3"></i> SEARCH</button></span>
		</div>
	</div>:null
}


{
    user.user.role === 'SHIPCONREPADMIN' &&  (location.pathname === RouteConstants.SURVEYREPORT) ? 
	<div class="sub-head ">
		<div class="filter-search">

			<FilterSelect 
				name="status" label="Ship Type" handleSelectChange={(e) => handleSelectChange(e, 'shiptype')} options={shiptypeoptions} 
				className="form-select form-select-sm rounded-5" 
			/>
		   <FilterSelect 
				name="status" label="Rank on Board" handleSelectChange={(e) => handleSelectChange(e, 'rank')} options={rankooptions} 
				className="form-select form-select-sm rounded-5" 
			/>

			<FilterSelect 
				name="status" label="Gender" handleSelectChange={(e) => handleSelectChange(e, 'gender')} options={genderOptions} 
				className="form-select form-select-sm rounded-5" 
			/>

			<FilterSelect 
				name="status" label="Age" handleSelectChange={(e) => handleSelectChange(e, 'age')} options={ageOptions} 
				className="form-select form-select-sm rounded-5" 
			/>
			
			
			<span class=" form-select-sm rounded-5"><input  value={fromDate} onChange={(e)=>{handleDatefilter(e,'from')}}   className="form-control form-control-sm" type="date" placeholder="From" /></span>
			<span class=" form-select-sm rounded-5"><input value={toDate} onChange={(e)=>{handleDatefilter(e,'to')}}  className="form-control form-control-sm" type="date" placeholder="TO" /></span>
			<span style={{display: 'none'}} class="dateinput-wrpr blank"><button type="button" onClick={handleReportfilterStatus} class="btn btn-sm btn-datesearch btn-primary"><i class="icon-search3"></i> SEARCH</button></span>
			
	
		</div>
	</div>:null
}



{
    (user.user.role === 'SHIPCONREPADMIN' || user.user.role === 'SUPERADMIN') &&   (location.pathname === RouteConstants.VESSEL ) ? 
	<div class="sub-head ">
		<div class="filter-search">
			<div class="input-group custom-search">
				<input type="text" class="form-control" placeholder="Vessel" onChange={(e) => handleSelectChange(e, 'vesselkey')}  />
				<span class="input-group-text"><i class="icon-search1"></i></span>
			</div>

			<FilterSelect 
					name="status" label="Doc Holder" handleSelectChange={(e) => handleSelectChange(e, 'doc')}  options={docoptions} 
					className="form-select form-select-sm rounded-5" 
			/>	
			<FilterSelect 
					name="status" label="Fleet" handleSelectChange={(e) => handleSelectChange(e, 'fleet')}  options={fleetoptions} 
					className="form-select form-select-sm rounded-5" 
			/>
			<FilterSelect 
					name="status" label="Status" handleSelectChange={(e) => handleSelectChange(e, 'status')} options={active} 
					className="form-select form-select-sm rounded-5" 
				/>	
		</div>
	</div>:null
}

{
    (user.user.role === 'SHIPCONREPADMIN' || user.user.role === 'SUPERADMIN') &&   (location.pathname === RouteConstants.DOC ) ? 
	<div class="sub-head ">
		<div class="filter-search">
			<div class="input-group custom-search">
				<input type="text" class="form-control" placeholder="Doc Holder" onChange={(e) => handleSelectChange(e, 'dockey')}  />
				<span class="input-group-text"><i class="icon-search1"></i></span>
			</div>

			<FilterSelect 
					name="status" label="Status" handleSelectChange={(e) => handleSelectChange(e, 'status')} options={active} 
					className="form-select form-select-sm rounded-5" 
				/>	
		</div>
	</div>:null
}

{
    (user.user.role === 'SHIPCONREPADMIN' || user.user.role === 'SUPERADMIN') &&   (location.pathname === RouteConstants.FLEET ) ? 
	<div class="sub-head ">
		<div class="filter-search">
			<div class="input-group custom-search">
				<input type="text" class="form-control" placeholder="Fleet" onChange={(e) => handleSelectChange(e, 'fleetkey')}  />
				<span class="input-group-text"><i class="icon-search1"></i></span>
			</div>

			<FilterSelect 
					name="status" label="Doc Holder" handleSelectChange={(e) => handleSelectChange(e, 'doc')}  options={docoptions} 
					className="form-select form-select-sm rounded-5" 
			/>	
			<FilterSelect 
					name="status" label="Status" handleSelectChange={(e) => handleSelectChange(e, 'status')} options={active} 
					className="form-select form-select-sm rounded-5" 
				/>	
		</div>
	</div>:null
}

{
    (user.user.role === 'SHIPCONREPADMIN' || user.user.role === 'SUPERADMIN') &&   (location.pathname === RouteConstants.USER ) ? 
	<div class="sub-head ">
		<div class="filter-search">
			<div class="input-group custom-search">
				<input type="text" class="form-control" placeholder="Name" onChange={(e) => handleSelectChange(e, 'userkey')}  />
				<span class="input-group-text"><i class="icon-search1"></i></span>
			</div>

			<FilterSelect 
					name="status" label="Role" handleSelectChange={(e) => handleSelectChange(e, 'role')}  options={roleOptions} 
					className="form-select form-select-sm rounded-5" 
			/>	
			<FilterSelect 
					name="status" label="Status" handleSelectChange={(e) => handleSelectChange(e, 'status')} options={active} 
					className="form-select form-select-sm rounded-5" 
				/>	
		</div>
	</div>:null
}

    <div className="content-area">
    	<Outlet selectedStatus={selectedStatus} />
    </div>
    </aside>
  )
}

export default Header